import {
  collection,
  query,
  where,
  getDocs,
  doc,
  writeBatch,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import firestore from ".";

export const markMessagesAsRead = async (
  conversationId: string,
  userId: string
) => {
  const conversationRef = doc(firestore, "conversations", conversationId);

  const snapshotConversation = await getDoc(conversationRef);
  if (!snapshotConversation.exists()) return;

  const messagesRef = collection(
    firestore,
    `conversations/${conversationId}/messages`
  );
  
  const messagesQueryReceiver = query(
    messagesRef,
    where("receiverId", "==", userId),
    where("read", "==", false)
  );

  const messagesQuerySender = query(
    messagesRef,
    where("senderId", "==", userId),
    where("read", "==", false)
  );

  const snapshotMessageReceiver = await getDocs(messagesQueryReceiver);
  const snapshotMessageSender = await getDocs(messagesQuerySender);

  const batch = writeBatch(firestore);

  snapshotMessageReceiver.forEach((doc) => {
    batch.update(doc.ref, { read: true });
  });

  snapshotMessageSender.forEach((doc) => {
    batch.update(doc.ref, { read: true });
  });

  await batch.commit();

  const conversationData = snapshotConversation.data();

  await updateDoc(conversationRef, {
    unreadMessages: {
      ...conversationData?.unreadMessages,
      '0': 0,
    },
  });
};
