import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import { FormControl } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUsers } from "../../api";
import BackdropLoader from "../../common/components/BackdropLoader";

import { HeaderType, UserOrderByType } from "../../types";
import { abbrState, getBadge } from "../../utils";
import { DateFunc } from "../../utils/dateFunction";
import { ButtonGroup } from "./../button/ButtonGroup";
import { TableCell, TableHead } from "./../table";
import EditUserDetail from "./EditUserDetail";
import ViewUserDetail from "./ViewUserDetail";
import { getAuth } from "firebase/auth";
import firebaseApp from "../../services/firebase";

export type SortType = {
  order_desc?: boolean;
  order_by?: UserOrderByType;
};

const userHeaders: HeaderType<UserOrderByType>[] = [
  {
    key: "user",
    label: "User",
    orderBy: "name",
  },
  {
    key: "id",
    label: "ID",
    orderBy: "id",
  },
  {
    key: "city",
    label: "City",
    orderBy: "city",
  },
  {
    key: "state",
    label: "State",
    orderBy: "state",
    className: "text-center",
  },
  {
    key: "email",
    label: "Email",
    orderBy: "email",
  },
  {
    key: "classes",
    label: "Classes",
    orderBy: "classes_count",
    className: "text-center",
  },
  {
    key: "status",
    label: "Status",
    orderBy: "status",
  },
  {
    key: "activated",
    label: "Activated",
    orderBy: "created",
  },
  {
    key: "last",
    label: "Last",
    orderBy: "updated",
  },
  {
    key: "action",
    label: "Action",
    className: "text-center",
  },
];

const Users: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [viewDetail, setViewDetail] = useState(false);
  const [userId, setUserId] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [detail, setDetail] = useState();
  const [searchText, setSearchText] = useState("");

  const [selectedOption, setSelectedOption] = useState(1);
  const [itemNumber, setItemNumber] = useState(10);

  useEffect(() => {
    const auth = getAuth(firebaseApp);

    setTimeout(() => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          console.log("[FIREBASE] User is signed in.");
        } else {
          auth.signOut().catch((error) => console.log(error));
          console.log("[FIREBASE] No user is signed in.");
          localStorage.removeItem("teache_token");
          window.location.href = "/";
        }
      });
    }, 3000);
  }, []);

  const [sort, setSort] = useState<SortType>({
    order_by: "name",
    order_desc: true,
  });

  const handleParentCallback = (childData: any) => {
    if (childData) {
      setShowModal(false);
    } else {
      setShowModal(false);
    }
  };

  const handleViewParentCallback = (childData: any) => {
    setViewDetail(childData);
  };

  const handleShowModal = (id: any, status: string) => {
    setShowModal(true);
    setUserId(id);
    setUserStatus(status);
  };

  const handleViewShowModal = (item: any) => {
    setViewDetail(true);
    setDetail(item);
  };

  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: [
      "users",
      selectedOption,
      itemNumber,
      searchText,
      sort.order_by,
      sort.order_desc,
    ],
    queryFn: () =>
      getUsers({
        page: selectedOption,
        limit: itemNumber,
        search: searchText,
        order_by: sort.order_by,
        order_desc: sort.order_desc,
      }),
  });

  const pages = data ? data.pages : 1;
  const page = data ? data.page : 1;

  const handlePrevious = (page: number) => {
    if (page <= 1) {
      setSelectedOption(1);
    } else {
      let current_page = page;
      let previous_page = Number(current_page - 1);
      setSelectedOption(previous_page);
    }
  };
  const handleNext = (page: number) => {
    if (page < pages) {
      let current_page = page;
      let next_page = Number(current_page) + 1;
      setSelectedOption(next_page);
    }
  };

  const handleClear = () => {
    setSearchText("");
    setSelectedOption(1);
  };

  const handleOption = () => {
    let content = [];

    for (var index = 1; index <= pages; index++) {
      content.push(
        <option key={index} value={index}>
          {index}
        </option>
      );
    }
    return content;
  };
  const handleSelectedOption = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(Number(e.target.value));
  };
  const handleSelectItem = (e: ChangeEvent<HTMLSelectElement>) => {
    setItemNumber(Number(e.target.value));
    setSelectedOption(1);
  };

  const handleSort = (order_by?: UserOrderByType) => {
    if (order_by) {
      setSort((currentSort) => {
        if (currentSort.order_by === order_by)
          return { order_by, order_desc: !currentSort.order_desc };
        return { order_by, order_desc: false };
      });
    }
  };

  return (
    <Fragment>
      <ToastContainer />
      <div className="row">
        <div className="col-xl-12 col-lg-12" style={{ padding: "0px" }}>
          <div
            className="card mb-4"
            style={{
              border: "none",
              borderRadius: "0px",
              background: "#F3F7FF",
            }}
          >
            <div className="card-body" style={{ padding: "0px" }}>
              <div className="d-flex mb-2" style={{ marginTop: "30px" }}>
                <div
                  style={{
                    //  marginLeft: "20px",
                    marginLeft: "1%",
                  }}
                >
                  <InputGroup
                    className="searchbar"
                    style={{
                      height: "51px",
                      width: "445px",
                      background: "#FFFFFF",
                      boxShadow: "-10px 1px 53px 7px rgba(27, 30, 123, 0.1)",
                      borderRadius: "10px",
                    }}
                  >
                    <FormControl
                      value={searchText}
                      onChange={(e) => {
                        setSelectedOption(1);
                        setSearchText(e.target.value);
                      }}
                      id="inlineFormInputGroup"
                      placeholder="Search User"
                      style={{ height: "100%", border: "none" }}
                    />
                    <InputGroup.Text
                      style={{
                        color: "#807CD6",
                        background: "#DDE9FF",
                        width: "56px",
                        border: "none",
                      }}
                    >
                      <img
                        src="/search.png"
                        alt=""
                        style={{
                          width: "24px",
                          height: "24px",
                          marginLeft: "5px",
                        }}
                      />
                    </InputGroup.Text>
                    {searchText !== "" && (
                      <button
                        // style={{ marginRight: 5 }}
                        className="btn btn-secondary btn-sm "
                        onClick={handleClear}
                      >
                        Clear
                      </button>
                    )}
                  </InputGroup>
                </div>

                <div
                  className="ms-auto"
                  style={{ paddingTop: "30px", paddingRight: "1.5%" }}
                >
                  <span
                    style={{
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "160%",
                      color: "#817EB7",
                    }}
                    className="totaluser"
                  >
                    Total users
                  </span>
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "160%",
                      color: "#5D59B4",
                      paddingLeft: "20px",
                    }}
                  >
                    {data ? data.count : 0}
                  </span>
                </div>
              </div>
              {showModal && (
                <EditUserDetail
                  onUpdateSuccess={() => refetch()}
                  userId={userId}
                  userStatus={userStatus}
                  showModal={showModal}
                  handleCallback={handleParentCallback}
                />
              )}
              {viewDetail && (
                <ViewUserDetail
                  detail={detail}
                  viewDetail={viewDetail}
                  handleViewParentCallback={handleViewParentCallback}
                />
              )}
              <div
                className="table-responsive portlet"
                style={{
                  borderRadius: "10px",
                  boxShadow: "-10px 1px 53px 7px rgba(27, 30, 123, 0.1)",
                  margin: "auto",
                  width: "98%",
                }}
              >
                <table className="table">
                  <thead>
                    <tr
                      style={{ height: "62px" }}
                      className="rounded-top bg-teache text-white"
                    >
                      {userHeaders.map((header) => (
                        <TableHead
                          key={header.key}
                          title={header.label}
                          showIcon={!!header.orderBy}
                          onClick={() => handleSort(header.orderBy)}
                          className={header.className}
                          iconClass={
                            header.orderBy === sort.order_by
                              ? sort.order_desc
                                ? "descending"
                                : "ascending"
                              : ""
                          }
                        />
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-teache">
                    {isLoading && (
                      <tr>
                        <td colSpan={10}>
                          <BackdropLoader />
                        </td>
                      </tr>
                    )}
                    {data && data.data ? (
                      data.data.map((item) => (
                        <tr key={item.id} className="text-teache">
                          <TableCell className="fw-bold">
                            <p
                              style={{
                                margin: 0,
                                padding: 0,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                maxWidth: "230px",
                                height: "100%",
                                color: "#5D59B4",
                              }}
                            >
                              <Link
                                style={{ all: "unset", cursor: "pointer" }}
                                to={`/users/${item.id}`}
                              >
                                {item.name}
                              </Link>
                            </p>
                          </TableCell>
                          <TableCell>
                            <Link
                              to={{
                                pathname: `/users/${item.id}`,
                                state: {
                                  userinfo: item,
                                },
                              }}
                              className="text-teache text-center"
                            >
                              {item.id}
                            </Link>
                          </TableCell>
                          <TableCell>{item.city}</TableCell>
                          <TableCell className="text-center">
                            {abbrState(item.state, "abbr")}
                          </TableCell>
                          <TableCell>{item.email}</TableCell>
                          <TableCell className="text-center">
                            <Link
                              to={{
                                pathname: `/users/${item.id}`,
                                state: {
                                  userinfo: item,
                                },
                              }}
                              className="text-teache"
                            >
                              {item.classes_count ? item.classes_count : 0}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {item.status === "active"
                              ? getBadge("active")
                              : item.status === "inactive"
                              ? getBadge("inactive")
                              : getBadge("blocked")}
                          </TableCell>
                          <TableCell>{DateFunc(item.created)}</TableCell>
                          <TableCell>{DateFunc(item.updated)}</TableCell>
                          <TableCell className="text-center">
                            <ButtonGroup
                              onDetail={() => handleViewShowModal(item)}
                              onEdit={() =>
                                handleShowModal(item.id, item.status)
                              }
                              // onDelete={() => handleDelete(item.id)}
                            />
                          </TableCell>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={10} className="text-center">
                          No User
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className="d-flex"
                style={{ color: "#6460F2", marginTop: "30px" }}
              >
                <div className="p-2 flex-grow-1">
                  <div style={{ paddingLeft: "1%" }}>
                    <label
                      className="totaluser"
                      style={{ marginRight: 10, color: "#817EB7" }}
                    >
                      Item per page:
                    </label>
                    <select
                      value={itemNumber}
                      onChange={handleSelectItem}
                      className="classic"
                      style={{
                        paddingLeft: "16px",
                        paddingRight: "16px",
                      }}
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                </div>
                <div className="p-2">
                  <div>
                    <label
                      className="totaluser"
                      style={{ marginRight: 10, color: "#817EB7" }}
                    >
                      Move to:
                    </label>
                    <select
                      value={selectedOption}
                      onChange={handleSelectedOption}
                      className="classic"
                    >
                      {handleOption()}
                    </select>
                  </div>
                </div>
                <div
                  style={{
                    marginRight: "1.5%",
                    paddingTop: "8px",
                  }}
                >
                  <div>
                    <label
                      className="totaluser"
                      style={{ color: "#817EB7", marginRight: "20px" }}
                    >
                      {`${
                        (page - 1) * itemNumber +
                        (data && data.data ? data.data.length : 0)
                      } of ${data ? data.count : 0}`}
                    </label>
                    <button
                      style={{
                        marginRight: 10,
                        background: "#DDE9FF",
                        width: "42px",
                        height: "42px",
                      }}
                      type="button"
                      onClick={() => handlePrevious(page)}
                      className="btn btn-default btn-sm"
                    >
                      <i
                        style={{ color: "#5D59B4" }}
                        className="fas fa-angle-left"
                      ></i>
                    </button>

                    <button
                      type="button"
                      onClick={() => handleNext(page)}
                      className="btn btn-default btn-sm"
                      style={{
                        background: "#DDE9FF",
                        width: "42px",
                        height: "42px",
                      }}
                    >
                      <i
                        style={{ color: "#5D59B4" }}
                        className="fas fa-angle-right"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Users;
