import axios from "axios"

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}admin`,
})

api.interceptors.request.use(
  (config) => {
    const storedToken = localStorage.getItem("teache_token")
    if (storedToken) {
      config.headers["Authorization"] = `Bearer ${storedToken}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export { api }
