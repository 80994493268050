import React from "react"
import { BrowserRouter as Router, Switch } from "react-router-dom"
import "./App.css"
import { AccountRoute } from "./common/components/AccountRoute"
import { PrivateRoute } from "./common/components/PrivateRoute"
import Login from "./components/Account/Login"
import Admin from "./components/Admin/Admin"
import "./styles/sb-admin-2.min.css"

import { QueryClient, QueryClientProvider } from "react-query"

const queryClient = new QueryClient()
const App: React.FC = () => {
  return (
    <div
      className='App'
      id='wrapper'>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Switch>
            <PrivateRoute path='/'>
              <Admin />
            </PrivateRoute>
            <AccountRoute path='/login'>
              <Login />
            </AccountRoute>
          </Switch>
        </Router>
      </QueryClientProvider>
    </div>
  )
}

export default App
