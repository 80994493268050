import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
  setDoc,
} from "firebase/firestore";
import firestore from ".";

export const sendMessage = async (
  text: string,
  conversationId: string,
  senderId: string,
  receiverId: string,
  receiverName: string
) => {
  const conversationRef = doc(firestore, "conversations", conversationId);
  const snapshotConversation = await getDoc(conversationRef);
  if (!snapshotConversation.exists()) return;

  const messagesRef = collection(conversationRef, "messages");

  const messageDoc = await addDoc(messagesRef, {
    senderId,
    receiverId,
    receiverName,
    text,
    timestamp: serverTimestamp(),
    read: false,
  });

  const lastUpdatedMessageId = messageDoc.id;

  const messagesUnreadReceiverQuery = query(
    collection(conversationRef, "messages"),
    where("read", "==", false),
    where("receiverId", "==", receiverId)
  );

  const messagesUnread = await getDocs(messagesUnreadReceiverQuery);
  const conversationData = snapshotConversation.data();

  await updateDoc(conversationRef, {
    lastMessage: text,
    lastUpdatedMessageId: lastUpdatedMessageId,
    lastMessageTimestamp: serverTimestamp(),
    unreadMessages: {
      ...conversationData?.unreadMessages,
      [receiverId]: messagesUnread.size,
    },
  });

  const lastUpdatedMessageRef = doc(
    firestore,
    "lastUpdatedMessage",
    conversationId
  );

  await setDoc(
    lastUpdatedMessageRef,
    {
      conversationId,
      messageId: lastUpdatedMessageId,
    },
    { merge: true }
  );
};
