import { HeaderType, IssuesOrderByType } from "../../types"

type HeaderIssuesType = HeaderType<IssuesOrderByType>

export const IssuesUserHeaders: HeaderIssuesType[] = [
  {
    key: "class-id",
    label: "ClassID",
    orderBy: "class_id",
    className: "text-center",
  },
  {
    key: "user",
    label: "User",
    orderBy: "student_name",
  },
  {
    key: "teacher_name",
    label: "Instructor",
    orderBy: "teacher_name",
  },
  {
    key: "class_date",
    label: "Class Date",
    orderBy: "class_date",
  },
  {
    key: "payment",
    label: "Payment",
    orderBy: "payment",
    className: "text-center",
  },
  {
    key: "cost",
    label: "Cost",
    orderBy: "cost",
    className: "text-center",
  },
  {
    key: "reported",
    label: "Reported",
    orderBy: "created_at",
  },
  {
    key: "chat",
    label: "Chat",
    orderBy: "channel_id",
  },
  {
    key: "issue",
    label: "Issue",
    orderBy: "id",
  },
]

export const IssuesTeacherHeaders: HeaderIssuesType[] = [
  {
    key: "class-id",
    label: "ClassID",
    orderBy: "class_id",
    className: "text-center",
  },
  {
    key: "teacher_name",
    label: "Instructor",
    orderBy: "teacher_name",
  },
  {
    key: "user",
    label: "User",
    orderBy: "student_name",
  },

  {
    key: "class_date",
    label: "Class Date",
    orderBy: "class_date",
  },
  {
    key: "payment",
    label: "Payment",
    orderBy: "payment",
    className: "text-center",
  },
  {
    key: "cost",
    label: "Cost",
    orderBy: "cost",
    className: "text-center",
  },
  {
    key: "reported",
    label: "Reported",
    orderBy: "created_at",
  },
  {
    key: "chat",
    label: "Chat",
    orderBy: "channel_id",
  },
  {
    key: "issue",
    label: "Issue",
    orderBy: "id",
  },
]
