import {  doc, onSnapshot } from "firebase/firestore";
import firestore from ".";

export const getMessagesUnreadCountByUser = (
  conversationId: string,
  callback: Function
) => {
  const conversationRef = doc(firestore, "conversations", conversationId);

  return onSnapshot(conversationRef, (docSnapshot) => {
    if (docSnapshot.exists()) {
      const conversationData = docSnapshot.data();
      const unreadMessages = conversationData.unreadMessages || {};
      const unreadCount = unreadMessages['0'] || 0;
      callback(unreadCount);
    } else {
      console.log("No such document!");
      callback(0);
    }
  });
};
