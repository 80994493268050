import React, { Dispatch, Fragment, useEffect, useState } from "react"
import { Button, Col, Modal } from "react-bootstrap"
import Image from "react-bootstrap/Image"
import { AiOutlineCaretDown } from "react-icons/ai"
import { useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import BaseUrl from "../../BaseUrl/BaseUrl"
import BackdropLoader from "../../common/components/BackdropLoader"
import { updateCurrentPath } from "../../store/actions/root.actions"
import { getBadge, getStatus, getTeacherBadge } from "../../utils"
import { DateFunc, convertDate } from "../../utils/dateFunction"
import Chat from "../Chat"
import { ButtonGroup } from "../button/ButtonGroup"
import EditTeacherDetail from "./EditTeacherDetail"
import ViewTeacherDetail from "./ViewTeacherDetail"

const Users: React.FC = (props: object) => {
  const { id } = useParams()
  const dispatch: Dispatch<any> = useDispatch()
  dispatch(updateCurrentPath("user", "list"))
  const [showModal, setShowModal] = useState(false)
  const [classesData, setClassesData] = useState([])
  const [teacherData, setTeacherData]: any[] = useState([])
  const [teacherClassType, setTeacherClassType] = useState("")
  const [techerUId, seteacherUId] = useState("")
  const [teacherId, setTeacherId] = useState("")
  const [userStatus, setUserStatus] = useState("")
  const [totalCount, setTotalCount] = useState(1)
  const [page, setPage] = useState(1)
  const [loader, setLoader] = useState(true)
  const [showModal_, setShowModal_] = useState(false)
  const [viewDetail_, setViewDetail_] = useState(false)
  const [showDelete, setDeleteModal] = useState(false)
  const [itemNumber, setItemNumber] = useState(10)
  const [showerCount, setShowerCount] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [pagesNumber, setPagesNumber] = useState(1)
  const [selectedUserid, setSelectedUserid] = useState("")
  const [selectedClassid, setSelectedClassid] = useState("")
  const [selectedUserName, setSelectedUserName] = useState("")
  const [teacherName, setTacherName] = useState("")

  const handleParentCallback = (childData: any) => {
    setShowModal(childData)
  }

  const handleViewParentCallback_ = (childData: any) => {
    setViewDetail_(childData)
  }

  const handleParentCallback_ = (childData: any) => {
    if (childData) {
      setShowModal_(false)
    } else {
      setShowModal_(false)
    }
  }

  const handleShowModal = (classid: any, user_id: any, user_name: any) => {
    setShowModal(true)
    setSelectedClassid(classid)
    setSelectedUserid(user_id)
    setSelectedUserName(user_name)
  }

  const handleShowModal_ = (userId: any, userStatus: string) => {
    setShowModal_(true)
    setUserStatus(userStatus)
  }

  const handleViewShowModal_ = (id: any, user: any) => {
    setViewDetail_(true)
  }

  const handleDeleteModal = (id: any) => {
    setDeleteModal(true)
  }

  const ConfirmDelete = (id: any) => {
    setDeleteModal(false)
    setLoader(true)
    const axiosConfig: any = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("teache_token")}`,
      },
    }

    BaseUrl.delete(`/delete-user/${techerUId}`, axiosConfig)
      .then((res) => {
        toast.success(res.data.message)
        cancelDelete()
        setLoader(false)
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message)
          cancelDelete()
          setLoader(false)
        }
      })
  }
  const cancelDelete = () => {
    setDeleteModal(false)
  }

  const getTeacher = ()=>{
    const axiosConfig: any = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("teache_token")}`,
      },
    }
    BaseUrl.get(`/teachers/${id}`, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            if (res.data.data) {
              if (res.data.data.user) {
                setTeacherData(res.data.data)
                setTeacherClassType(res.data.data.class.title)
                setTeacherId(res.data.data.id)
                seteacherUId(res.data.data.user.id)
                setTacherName(
                  res.data.data.user.first_name +
                    " " +
                    res.data.data.user.last_name
                )
              }
            }
          } else {
            setTotalCount(0)
            setTeacherData([])
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          setLoader(false)
          setTeacherData([])
          setLoader(false)
        }
      })
  }

  useEffect(() => {
    getTeacher()
  }, [showModal_, id])

  useEffect(() => {
    setLoader(true)
    const axiosConfig: any = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("teache_token")}`,
      },
    }
    BaseUrl.get(
      `/classes/${id}?limit=${itemNumber}&&page=${page}`,
      axiosConfig
    ).then((res) => {
      setLoader(false)
      if (res.status === 200) {
        if (res.data) {
          setTotalCount(res.data.count)
          setClassesData(res.data.data)
          setPageNumber(res.data.page)
          setPagesNumber(res.data.pages)
          if (res.data.data) {
            setShowerCount(res.data.data.length)
          }
        } else {
          setClassesData([])
          setTotalCount(0)
        }
      } else {
        setClassesData([])
        setTotalCount(0)
      }
    })
  }, [id, itemNumber, page])

  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config)

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items]
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1
          }
          return 0
        })
      }
      return sortableItems
    }, [items, sortConfig])

    const requestSort = (key) => {
      let direction = "ascending"
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending"
      }
      setSortConfig({ key, direction })
    }

    return { items: sortedItems, requestSort, sortConfig }
  }

  const { items, requestSort, sortConfig } = useSortableData(classesData)
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return
    }
    return sortConfig.key === name ? sortConfig.direction : undefined
  }

  const handlePrevious = (page: any) => {
    if (page <= 1) {
      page = 1
      setPage(page)
    } else {
      let current_page = page
      let previous_page = Number(current_page - 1)
      setPage(previous_page)
    }
  }
  const handleNext = (page: any) => {
    if (page < pagesNumber) {
      let current_page = page
      let next_page = Number(current_page) + 1
      setPage(next_page)
    }
  }

  const handleOption = () => {
    let content = []
    for (var index = 1; index <= pagesNumber; index++) {
      content.push(
        <option
          key={index}
          value={index}>
          {index}
        </option>
      )
    }
    return content
  }
  const handleSelectItem = (e: any) => {
    setItemNumber(e.target.value)
  }
  const handleSelectedOption = (e: any) => {
    setPage(e.target.value)
  }

  return (
    <Fragment>
      <ToastContainer />
      <Modal
        centered
        show={showDelete}
        backdrop='static'
        onHide={() => setDeleteModal(false)}
        aria-labelledby='example-modal-sizes-title-lg'>
        <div className='p-3'>
          <Modal.Title id='example-modal-sizes-title-sm'>
            Are you sure to delete this teacher ?
          </Modal.Title>

          <Modal.Body>
            <div className=' d-flex flex-row w-100  mt-2'>
              <button
                onClick={cancelDelete}
                className='btn btn-danger  mr-3 btn-sm'>
                Cancel
              </button>
              <button
                onClick={ConfirmDelete}
                className='btn btn-primary btn-sm'>
                Confirm
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <div
        className='row'
        style={{ background: "#F3F7FF" }}>
        <div
          className='col-xl-12 col-lg-12'
          style={{ padding: "0px" }}>
          <div
            className='card mb-4'
            style={{
              border: "none",
              borderRadius: "0px",
              background: "#F9FBFF",
            }}>
            <div
              className='card-header'
              style={{
                border: "none",
                background: "#F3F7FF",
                padding: "25px",
              }}>
              <div>
                <Col
                  md={{ span: 2, offset: 5 }}
                  style={{ textAlign: "center" }}>
                  <Image
                    className='customAvatar'
                    fluid
                    src={
                      teacherData && teacherData.profile_pic
                        ? teacherData.profile_pic
                        : "/profile.png"
                    }
                    style={{
                      borderRadius: "20px",
                      width: "110px",
                      height: "110px",
                    }}
                  />
                </Col>
              </div>
            </div>
            <div
              className='card-body'
              style={{ padding: "0px", background: "#F3F7FF" }}>
              <div
                className='table-responsive portlet'
                style={{
                  borderRadius: "10px",
                  boxShadow: "-10px 1px 53px 7px rgba(27, 30, 123, 0.1)",
                  margin: "auto",
                  width: "98%",
                }}>
                <table className='table teacher'>
                  <thead>
                    <tr
                      style={{
                        backgroundColor: "#6460F2",
                        color: "white",
                        height: "62px",
                      }}
                      className='rounded-top'>
                      {/* <th scope="col">#</th> */}
                      <th
                        scope='col'
                        style={{
                          paddingLeft: 15 + "px",
                          verticalAlign: "middle",
                        }}>
                        Instructor
                      </th>
                      <th
                        scope='col'
                        className='text-center'
                        style={{ verticalAlign: "middle" }}>
                        Ins ID
                      </th>
                      <th
                        scope='col'
                        style={{ verticalAlign: "middle" }}>
                        City
                      </th>
                      <th
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}>
                        State
                      </th>
                      <th
                        scope='col'
                        style={{ verticalAlign: "middle" }}>
                        Class Type
                      </th>
                      <th
                        scope='col'
                        style={{ verticalAlign: "middle" }}>
                        Email
                      </th>
                      <th
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}>
                        Classes
                      </th>
                      <th
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}>
                        Status
                      </th>
                      <th
                        scope='col'
                        style={{ verticalAlign: "middle" }}>
                        Activated
                      </th>
                      <th
                        scope='col'
                        style={{ verticalAlign: "middle" }}>
                        Last
                      </th>
                      <th
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ color: "#6460F2", background: "white" }}>
                    {teacherData && teacherData.user && (
                      <tr>
                        <td
                          style={{
                            paddingLeft: 15 + "px",
                            color: "#817EB7",
                          }}>
                          {teacherData.user.first_name}{" "}
                          {teacherData.user.last_name}
                        </td>
                        <td
                          style={{ color: "#817EB7" }}
                          className='text-center'>
                          {id}
                        </td>
                        <td style={{ color: "#817EB7" }}>

                          {teacherData.city && teacherData.city}

                        </td>
                        <td style={{ color: "#817EB7", textAlign: "center" }}>
                          {teacherData.state}
                        </td>
                        <td style={{ color: "#817EB7" }}>{teacherClassType}</td>
                        <td style={{ color: "#817EB7" }}>
                          {teacherData.user.email}
                        </td>
                        <td style={{ color: "#817EB7", textAlign: "center" }}>
                          <Link
                            style={{ color: "#817EB7" }}
                            to={`/users/${teacherData.user.id}`}>
                            {teacherData.classes_given}
                          </Link>
                        </td>
                        <td style={{ color: "#817EB7", textAlign: "center" }}>
                          {teacherData.is_deleted
                            ? getBadge("deleted")
                            : getTeacherBadge(teacherData.status)}
                        </td>
                        <td style={{ color: "#817EB7" }}>
                          {DateFunc(teacherData.created_at)}
                        </td>
                        <td style={{ color: "#817EB7" }}>
                          {DateFunc(teacherData.updated_at)}
                        </td>
                        <td className='text-center'>
                            <ButtonGroup
                              onDetail={() =>
                                handleViewShowModal_(id, teacherData.user)
                              }
                              onEdit={() =>
                                handleShowModal_(
                                  teacherData.user.id,
                                  teacherData.user.status
                                )
                              }
                              onDelete={() =>   handleDeleteModal(teacherData.user.id)}
                            />
                          </td>
                        
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div
                className='d-flex mb-2'
                style={{ marginTop: "30px" }}>
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: "32px",
                    lineHeight: "160%",
                    color: "#6460F2",
                    paddingLeft: "1%",
                  }}>
                  <b>Classes</b>
                </div>
                <div
                  className='ms-auto'
                  style={{ paddingTop: "30px", paddingRight: "1.5%" }}>
                  <span
                    style={{
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "160%",
                      color: "#817EB7",
                    }}>
                    Total Classes
                  </span>
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "160%",
                      color: "#5D59B4",
                      paddingLeft: "20px",
                      textAlign: "center",
                    }}>
                    {totalCount}
                  </span>
                </div>
              </div>
              <div
                className='table-responsive portlet'
                style={{
                  borderRadius: "10px",
                  boxShadow: "-10px 1px 53px 7px rgba(27, 30, 123, 0.1)",
                  margin: "auto",
                  width: "98%",
                }}>
                <table className='table'>
                  <thead>
                    <tr
                      style={{
                        backgroundColor: "#DDE9FF",
                        color: "#6460F2",
                        height: "62px",
                      }}
                      className='rounded-top'>
                      <th
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          border: "none",
                          textAlign: "center",
                        }}>
                        Class ID
                        <AiOutlineCaretDown
                          onClick={() => requestSort("id")}
                          className={getClassNamesFor("id")}
                        />
                      </th>
                      <th
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                          border: "none",
                        }}>
                        User
                        <AiOutlineCaretDown
                          onClick={() => requestSort("name")}
                          className={getClassNamesFor("name")}
                        />
                      </th>
                      <th
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          border: "none",
                          textAlign: "center",
                        }}>
                        User ID
                        <AiOutlineCaretDown
                          onClick={() => requestSort("user_id")}
                          className={getClassNamesFor("user_id")}
                        />
                      </th>
                      <th
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                          border: "none",
                        }}>
                        Booking ID
                        <AiOutlineCaretDown
                          onClick={() => requestSort("booking_id")}
                          className={getClassNamesFor("booking_id")}
                        />
                      </th>
                      <th
                        scope='col'
                        style={{ verticalAlign: "middle", border: "none" }}>
                        Class Date
                        <AiOutlineCaretDown
                          onClick={() => requestSort("class_date")}
                          className={getClassNamesFor("class_date")}
                        />
                      </th>
                      <th
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                          border: "none",
                        }}>
                        Status
                        <AiOutlineCaretDown
                          onClick={() => requestSort("status")}
                          className={getClassNamesFor("status")}
                        />
                      </th>
                      <th
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                          border: "none",
                        }}>
                        Cost
                        <AiOutlineCaretDown
                          onClick={() => requestSort("cost")}
                          className={getClassNamesFor("cost")}
                        />
                      </th>
                      <th
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                          border: "none",
                        }}>
                        Payment
                        <AiOutlineCaretDown
                          onClick={() => requestSort("payment")}
                          className={getClassNamesFor("payment")}
                        />
                      </th>
                      <th
                        scope='col'
                        className='text-center'
                        style={{ verticalAlign: "middle", border: "none" }}>
                        Chat
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ color: "#6460F2" }}>
                    {loader && (
                      <tr>
                        <td colSpan={8}>
                          <BackdropLoader />
                        </td>
                      </tr>
                    )}
                    {items &&
                      items.length > 0 &&
                      items.map((item: any, index) => {
                        return (
                          <tr key={index}>
                            <td
                              style={{
                                color: "#817EB7",
                                textAlign: "center",
                              }}>
                              <Link
                                to={{
                                  pathname: `/cancelledclasses/${item.id}`,
                                  state: {
                                    userinfo: item,
                                  },
                                }}
                                style={{ color: "#817EB7" }}>
                                <span>{item.id}</span>
                              </Link>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <Link
                                to={{
                                  pathname: `/users/${item.user_id}`,
                                  state: {
                                    userinfo: item,
                                  },
                                }}
                                style={{ color: "#817EB7" }}>
                                {item.name}
                              </Link>
                            </td>
                            <td
                              style={{ color: "#817EB7", textAlign: "center" }}>
                              <Link
                                to={{
                                  pathname: `/users/${item.user_id}`,
                                  state: {
                                    userinfo: item,
                                  },
                                }}
                                style={{ color: "#817EB7" }}>
                                {item.user_id}
                              </Link>
                            </td>
                            <td
                              style={{ color: "#817EB7", textAlign: "center" }}>
                              <Link
                                to={{
                                  pathname: `/booking/${item.booking_id}`,
                                  state: {
                                    userinfo: item,
                                  },
                                }}
                                style={{ color: "#817EB7" }}>
                                {item.booking_id}
                              </Link>
                            </td>
                            <td style={{ color: "#817EB7" }}>
                              {convertDate(item.class_date)}
                            </td>
                            <td
                              style={{ color: "#817EB7", textAlign: "center" }}>
                              {getStatus(item.status)}
                            </td>
                            <td
                              style={{
                                color: "#817EB7",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}>
                              $ {item.cost}
                            </td>
                            <td
                              style={{
                                color: "#817EB7",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}>
                              {item.payment}
                            </td>
                            <td
                              style={{ color: "#817EB7", textAlign: "center" }}>
                              <Button
                                onClick={() =>
                                  handleShowModal(
                                    item.id,
                                    item.user_id,
                                    item.name
                                  )
                                }
                                style={{
                                  all: "unset",
                                  color: "#817EB7",
                                }}>
                                Chat
                              </Button>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
              <div
                className='d-flex'
                style={{ color: "#6460F2", marginTop: "30px" }}>
                <div className='p-2 flex-grow-1'>
                  <div style={{ paddingLeft: "1%" }}>
                    <label
                      className='totaluser'
                      style={{ marginRight: 10, color: "#817EB7" }}>
                      Item per page:
                    </label>
                    <select
                      value={itemNumber}
                      onChange={(e) => handleSelectItem(e)}
                      className='classic'
                      style={{
                        paddingLeft: "16px",
                        paddingRight: "16px",
                      }}>
                      <option value='5'>5</option>
                      <option value='10'>10</option>
                      <option value='15'>15</option>
                      <option value='20'>20</option>
                    </select>
                  </div>
                </div>
                <div className='p-2'>
                  <div>
                    <label
                      className='totaluser'
                      style={{ marginRight: 10, color: "#817EB7" }}>
                      Move to:
                    </label>
                    <select
                      value={page}
                      onChange={(e) => handleSelectedOption(e)}
                      className='classic'>
                      {handleOption()}
                    </select>
                  </div>
                </div>
                <div
                  style={{
                    marginRight: "1.5%",
                    paddingTop: "8px",
                  }}>
                  <div>
                    <label
                      className='totaluser'
                      style={{ color: "#817EB7", marginRight: "20px" }}>
                      {showerCount + (pageNumber - 1) * itemNumber} of{" "}
                      {totalCount}
                    </label>
                    <button
                      style={{
                        marginRight: 10,
                        background: "#DDE9FF",
                        width: "42px",
                        height: "42px",
                      }}
                      type='button'
                      onClick={() => handlePrevious(pageNumber)}
                      className='btn btn-default btn-sm'>
                      <i
                        style={{ color: "#5D59B4" }}
                        className='fas fa-angle-left'></i>
                    </button>

                    <button
                      type='button'
                      onClick={() => handleNext(pageNumber)}
                      className='btn btn-default btn-sm'
                      style={{
                        background: "#DDE9FF",
                        width: "42px",
                        height: "42px",
                      }}>
                      <i
                        style={{ color: "#5D59B4" }}
                        className='fas fa-angle-right'></i>
                    </button>
                    {showModal_ && (
                      <EditTeacherDetail
                      onUpdateSuccess={()=>{getTeacher()}}
                      userId={teacherData.id}
                      userStatus={teacherData.status}
                      showModal={showModal_}
                      handleCallback={handleParentCallback_}
                    />
                    
                    )}
                    {viewDetail_ && (
                      <ViewTeacherDetail
                        // detail={detail}
                        userId={teacherId}
                        viewDetail={viewDetail_}
                        handleViewParentCallback={handleViewParentCallback_}
                      />
                    )}
                    {showModal && (
                      <Chat
                        classId={selectedClassid}
                        userId={selectedUserid}
                        teacherId={techerUId}
                        userName={selectedUserName}
                        teacherName={teacherName}
                        showModal={showModal}
                        handleCallback={handleParentCallback}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Users
