import React from "react"

type ButtonProps = React.HTMLAttributes<HTMLButtonElement>

export const Button: React.FC<ButtonProps> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <button
      className={`btn btn-secondary btn-sm ${className}`}
      {...rest}>
      {children}
    </button>
  )
}
