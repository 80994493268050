import React from "react"
import { Button } from "./Button"

type onClick = React.HTMLAttributes<HTMLButtonElement>["onClick"]
type ButtonGroupProps = {
  onDetail?: onClick
  onDelete?: onClick
  onEdit?: onClick
}

export const ButtonGroup: React.FC<ButtonGroupProps> = ({
  onDelete,
  onDetail,
  onEdit,
}) => {
  return (
    <>
      {onDetail ? (
        <Button
          className='actionBtn'
          onClick={onDetail}>
          <img
            src='/eye.png'
            alt=''
            width={16}
            height={16}
          />
        </Button>
      ) : null}
      {onEdit ? (
        <Button
          onClick={onEdit}
          className='actionBtn2'>
          <svg
            width='17'
            height='17'
            viewBox='0 0 17 17'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M10.571 2.82325L14.0292 6.28141L5.27558 15.035L1.81936 11.5769L10.571 2.82325ZM16.6533 1.98923L15.1111 0.44701C14.5151 -0.149003 13.5473 -0.149003 12.9492 0.44701L11.4719 1.9243L14.9301 5.38248L16.6533 3.65931C17.1156 3.19701 17.1156 2.4515 16.6533 1.98923ZM0.00962331 16.4376C-0.0533112 16.7208 0.202413 16.9746 0.485682 16.9057L4.33925 15.9714L0.883025 12.5132L0.00962331 16.4376Z'
              fill='#807CD6'
            />
          </svg>
        </Button>
      ) : null}
      {onDelete ? (
        <Button
          onClick={onDelete}
          className='actionBtn2'>
          <svg
            width='17'
            height='19'
            viewBox='0 0 17 19'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M15.375 3.25H11.625V2.625C11.625 1.59125 10.7837 0.75 9.75 0.75H7.25C6.21625 0.75 5.375 1.59125 5.375 2.625V3.25H1.625C0.935625 3.25 0.375 3.81063 0.375 4.5V5.125C0.375 5.47 0.655 5.75 1 5.75H16C16.345 5.75 16.625 5.47 16.625 5.125V4.5C16.625 3.81063 16.0644 3.25 15.375 3.25ZM6.625 2.625C6.625 2.28062 6.90562 2 7.25 2H9.75C10.0944 2 10.375 2.28062 10.375 2.625V3.25H6.625V2.625Z'
              fill='#807CD6'
            />
            <path
              d='M2.25 7V16.375C2.25 17.4087 3.09125 18.25 4.125 18.25H12.875C13.9087 18.25 14.75 17.4087 14.75 16.375V7H2.25ZM10.8169 13.4331C11.0613 13.6775 11.0613 14.0725 10.8169 14.3169C10.5725 14.5613 10.1775 14.5613 9.93312 14.3169L8.5 12.8837L7.06687 14.3169C6.8225 14.5613 6.4275 14.5613 6.18313 14.3169C5.93875 14.0725 5.93875 13.6775 6.18313 13.4331L7.61625 12L6.18313 10.5669C5.93875 10.3225 5.93875 9.9275 6.18313 9.68312C6.4275 9.43875 6.8225 9.43875 7.06687 9.68312L8.5 11.1163L9.93312 9.68312C10.1775 9.43875 10.5725 9.43875 10.8169 9.68312C11.0613 9.9275 11.0613 10.3225 10.8169 10.5669L9.38375 12L10.8169 13.4331Z'
              fill='#807CD6'
            />
          </svg>
        </Button>
      ) : null}
    </>
  )
}
