import React from "react"
import { AiOutlineCaretDown } from "react-icons/ai"

type TableProps = React.HTMLAttributes<HTMLTableElement>

type TableCellProps = React.HTMLAttributes<HTMLTableCellElement>

export const Table: React.FC<TableProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <table
      className={`table ${className}`}
      {...rest}>
      {children}
    </table>
  )
}

export const TableCell: React.FC<TableCellProps> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <td
      className={`px-3 align-middle text-nowrap ${className}`}
      {...rest}>
      {children}
    </td>
  )
}

export const TableHead: React.FC<
  TableCellProps & {
    title: string
    showIcon?: boolean
    iconClass?: string
  }
> = ({ className, title, showIcon, iconClass, ...rest }) => {
  return (
    <th
      scope='col'
      className={`px-3 align-middle text-nowrap position-relative ${className}`}
      style={{ cursor: "pointer" }}
      {...rest}>
      {title}
      {showIcon && (
        <AiOutlineCaretDown
          style={{ marginTop: "4.5px" }}
          className={`${iconClass} position-absolute`}
        />
      )}
    </th>
  )
}
