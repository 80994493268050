import { GetTeachersParamsType, GetTeachersType } from "../types"
import { api } from "./base.api"

export const getTeachers = async (
  params: GetTeachersParamsType
): Promise<GetTeachersType> => {
  const res = await api.get(`/teachers`, {
    params: params,
  })
  return res.data
}

export const deleteTeacher = async (teacherId: string) => {
  const res = await api.delete(`/delete-teacher/${teacherId}`)
  return res.data
}
