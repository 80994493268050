import React, { ChangeEvent, Dispatch, Fragment, useState } from "react"
import { Button } from "react-bootstrap"
import { AiOutlineCaretDown } from "react-icons/ai"
import { useQuery } from "react-query"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { getBookedClasses } from "../../api/classes.api"
import BackdropLoader from "../../common/components/BackdropLoader"
import { updateCurrentPath } from "../../store/actions/root.actions"
import { BookedClasses as BookedClassesType } from "../../types/classes.type"
import { getStatus } from "../../utils"
import { convertDate } from "../../utils/dateFunction"
import Chat from "../Chat"

const defaultShowChat = {
  showModal: false,
  userId: 0,
  userName: "",
  teacherId: 0,
  teacherName: "",
  classId: 0,
}

const BookedClasses: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch()
  dispatch(updateCurrentPath("user", "list"))
  const [itemNumber, setItemNumber] = useState(10)
  const [selectedOption, setSelectedOption] = useState(1)
  const [showChat, setShowChat] = useState(defaultShowChat)

  const handleShowModal = (newChat: typeof defaultShowChat) => {
    setShowChat((current) => ({ ...current, ...newChat, showModal: true }))
  }

  const { data, isLoading: loader } = useQuery({
    queryKey: ["bookedClasses", selectedOption, itemNumber],
    queryFn: () =>
      getBookedClasses({
        page: selectedOption,
        limit: itemNumber,
      }),
  })

  const pages = data ? data.pages : 1
  const page = data ? data.page : 1
  const classesData = data?.data || []
  console.log(classesData)

  const useSortableData = (items: BookedClassesType[], config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config)

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items]
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1
          }
          return 0
        })
      }
      return sortableItems
    }, [items, sortConfig])

    const requestSort = (key) => {
      let direction = "ascending"
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending"
      }
      setSortConfig({ key, direction })
    }

    return { items: sortedItems, requestSort, sortConfig }
  }

  const { items, requestSort, sortConfig } = useSortableData(classesData)

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return
    }
    return sortConfig.key === name ? sortConfig.direction : undefined
  }

  const handlePrevious = (page: number) => {
    if (page <= 1) {
      setSelectedOption(1)
    } else {
      let current_page = page
      let previous_page = Number(current_page - 1)
      setSelectedOption(previous_page)
    }
  }
  const handleNext = (page: number) => {
    if (page < pages) {
      let current_page = page
      let next_page = Number(current_page) + 1
      setSelectedOption(next_page)
    }
  }

  const handleOption = () => {
    let content = []
    for (var index = 1; index <= pages; index++) {
      content.push(
        <option
          key={index}
          value={index}>
          {index}
        </option>
      )
    }
    return content
  }

  const handleSelectedOption = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(Number(e.target.value))
  }
  const handleSelectItem = (e: ChangeEvent<HTMLSelectElement>) => {
    setItemNumber(Number(e.target.value))
    setSelectedOption(1)
  }

  return (
    <Fragment>
      <ToastContainer />

      <div
        className='row'
        style={{ background: "#F3F7FF" }}>
        <div
          className='col-xl-12 col-lg-12'
          style={{ padding: "0px" }}>
          <div
            className='card mb-4'
            style={{
              border: "none",
              borderRadius: "0px",
              background: "#F9FBFF",
            }}>
            <div
              className='card-body'
              style={{ padding: "0px", background: "#F3F7FF" }}>
              <div
                className='d-flex mb-2'
                style={{ marginTop: "30px" }}>
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: "32px",
                    lineHeight: "160%",
                    color: "#6460F2",
                    paddingLeft: "1%",
                  }}>
                  <b>Classes</b>
                </div>
              </div>
              <div
                className='table-responsive portlet'
                style={{
                  borderRadius: "10px",
                  boxShadow: "-10px 1px 53px 7px rgba(27, 30, 123, 0.1)",
                  margin: "auto",
                  width: "98%",
                }}>
                <table className='table'>
                  <thead>
                    <tr
                      style={{
                        backgroundColor: "#DDE9FF",
                        color: "#6460F2",
                        height: "62px",
                      }}
                      className='rounded-top'>
                      <th
                        onClick={() => requestSort("id")}
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          border: "none",
                          textAlign: "center",
                          cursor: "pointer",
                        }}>
                        Class ID
                        <AiOutlineCaretDown
                          className={getClassNamesFor("id")}
                        />
                      </th>
                      <th
                        onClick={() => requestSort("class_type")}
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          border: "none",
                          textAlign: "center",
                          cursor: "pointer",
                        }}>
                        Class type
                        <AiOutlineCaretDown
                          className={getClassNamesFor("class_type")}
                        />
                      </th>
                      <th
                        onClick={() => requestSort("teacher_name")}
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          border: "none",
                          textAlign: "center",
                          cursor: "pointer",
                        }}>
                        Instuctors
                        <AiOutlineCaretDown
                          className={getClassNamesFor("teacher_name")}
                        />
                      </th>
                      <th
                        onClick={() => requestSort("user_name")}
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                          border: "none",
                          cursor: "pointer",
                        }}>
                        User
                        <AiOutlineCaretDown
                          className={getClassNamesFor("user_name")}
                        />
                      </th>
                      <th
                        onClick={() => requestSort("user_id")}
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          border: "none",
                          textAlign: "center",
                          cursor: "pointer",
                        }}>
                        User ID
                        <AiOutlineCaretDown
                          className={getClassNamesFor("user_id")}
                        />
                      </th>
                      <th
                        onClick={() => requestSort("booking_id")}
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                          border: "none",
                          cursor: "pointer",
                        }}>
                        Booking ID
                        <AiOutlineCaretDown
                          className={getClassNamesFor("booking_id")}
                        />
                      </th>
                      <th
                        onClick={() => requestSort("class_date")}
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          border: "none",
                          cursor: "pointer",
                        }}>
                        Class Date
                        <AiOutlineCaretDown
                          className={getClassNamesFor("class_date")}
                        />
                      </th>
                      <th
                        onClick={() => requestSort("status")}
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                          border: "none",
                          cursor: "pointer",
                        }}>
                        Status
                        <AiOutlineCaretDown
                          className={getClassNamesFor("status")}
                        />
                      </th>
                      <th
                        onClick={() => requestSort("cost")}
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                          border: "none",
                          cursor: "pointer",
                        }}>
                        Cost
                        <AiOutlineCaretDown
                          className={getClassNamesFor("cost")}
                        />
                      </th>
                      <th
                        onClick={() => requestSort("payment")}
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                          border: "none",
                          cursor: "pointer",
                        }}>
                        Payment
                        <AiOutlineCaretDown
                          className={getClassNamesFor("payment")}
                        />
                      </th>
                      <th
                        scope='col'
                        className='text-center'
                        style={{ verticalAlign: "middle", border: "none" }}>
                        Chat
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ color: "#6460F2" }}>
                    {loader && (
                      <tr>
                        <td colSpan={8}>
                          <BackdropLoader />
                        </td>
                      </tr>
                    )}
                    {items &&
                      items.length > 0 &&
                      items.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td
                              style={{
                                color: "#817EB7",
                                textAlign: "center",
                              }}>
                              <Link
                                to={{
                                  pathname: `/cancelledclasses/${item.id}`,
                                  state: {
                                    userinfo: item,
                                  },
                                }}
                                style={{ color: "#817EB7" }}>
                                <span>{item.id}</span>
                              </Link>
                            </td>
                            <td
                              style={{
                                color: "#817EB7",
                                textAlign: "center",
                              }}>
                              <span style={{ color: "#817EB7" }}>
                                {item.class_type}
                              </span>
                            </td>
                            <td
                              style={{
                                color: "#817EB7",
                                textAlign: "center",
                              }}>
                              <Link
                                to={{
                                  pathname: `/instructor/${item.teacher_id}`,
                                }}
                                style={{ color: "#817EB7" }}>
                                <span>{item.teacher_name}</span>
                              </Link>
                            </td>

                            <td style={{ textAlign: "center" }}>
                              <Link
                                to={{
                                  pathname: `/users/${item.user_id}`,
                                  state: {
                                    userinfo: item,
                                  },
                                }}
                                style={{ color: "#817EB7" }}>
                                {item.user_name}
                              </Link>
                            </td>
                            <td
                              style={{ color: "#817EB7", textAlign: "center" }}>
                              <Link
                                to={{
                                  pathname: `/users/${item.user_id}`,
                                  state: {
                                    userinfo: item,
                                  },
                                }}
                                style={{ color: "#817EB7" }}>
                                {item.user_id}
                              </Link>
                            </td>
                            <td
                              style={{ color: "#817EB7", textAlign: "center" }}>
                              <Link
                                to={{
                                  pathname: `/booking/${item.booking_id}`,
                                  state: {
                                    userinfo: item,
                                  },
                                }}
                                style={{ color: "#817EB7" }}>
                                {item.booking_id}
                              </Link>
                            </td>
                            <td style={{ color: "#817EB7" }}>
                              {convertDate(item.class_date)}
                            </td>
                            <td
                              style={{ color: "#817EB7", textAlign: "center" }}>
                              {getStatus(item.status)}
                            </td>
                            <td
                              style={{
                                color: "#817EB7",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}>
                              $ {item.cost}
                            </td>
                            <td
                              style={{
                                color: "#817EB7",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}>
                              {item.payment}
                            </td>
                            <td
                              style={{ color: "#817EB7", textAlign: "center" }}>
                              <Button
                                onClick={() =>
                                  handleShowModal({
                                    showModal: true,
                                    userId: item.user_id,
                                    userName: item.user_name,
                                    teacherId: item.teacher_id,
                                    teacherName: item.teacher_name,
                                    classId: item.id,
                                  })
                                }
                                style={{
                                  all: "unset",
                                  color: "#817EB7",
                                }}>
                                Chat
                              </Button>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
              <div
                className='d-flex'
                style={{ color: "#6460F2", marginTop: "30px" }}>
                <div className='p-2 flex-grow-1'>
                  <div style={{ paddingLeft: "1%" }}>
                    <label
                      className='totaluser'
                      style={{ marginRight: 10, color: "#817EB7" }}>
                      Item per page:
                    </label>
                    <select
                      value={itemNumber}
                      onChange={(e) => handleSelectItem(e)}
                      className='classic'
                      style={{
                        paddingLeft: "16px",
                        paddingRight: "16px",
                      }}>
                      <option value='5'>5</option>
                      <option value='10'>10</option>
                      <option value='15'>15</option>
                      <option value='20'>20</option>
                    </select>
                  </div>
                </div>
                <div className='p-2'>
                  <div>
                    <label
                      className='totaluser'
                      style={{ marginRight: 10, color: "#817EB7" }}>
                      Move to:
                    </label>
                    <select
                      value={selectedOption}
                      onChange={handleSelectedOption}
                      className='classic'>
                      {handleOption()}
                    </select>
                  </div>
                </div>
                <div
                  style={{
                    marginRight: "1.5%",
                    paddingTop: "8px",
                  }}>
                  <div>
                    <label
                      className='totaluser'
                      style={{ color: "#817EB7", marginRight: "20px" }}>
                      {`${
                        (page - 1) * itemNumber +
                        (data && data.data ? data.data.length : 0)
                      } of ${data ? data.count : 0}`}
                    </label>
                    <button
                      style={{
                        marginRight: 10,
                        background: "#DDE9FF",
                        width: "42px",
                        height: "42px",
                      }}
                      type='button'
                      onClick={() => handlePrevious(page)}
                      className='btn btn-default btn-sm'>
                      <i
                        style={{ color: "#5D59B4" }}
                        className='fas fa-angle-left'></i>
                    </button>

                    <button
                      type='button'
                      onClick={() => handleNext(page)}
                      className='btn btn-default btn-sm'
                      style={{
                        background: "#DDE9FF",
                        width: "42px",
                        height: "42px",
                      }}>
                      <i
                        style={{ color: "#5D59B4" }}
                        className='fas fa-angle-right'></i>
                    </button>

                    {showChat.showModal && (
                      <Chat
                        {...showChat}
                        handleCallback={() => setShowChat(defaultShowChat)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default BookedClasses
