import { getTypingRef } from "./getTypingRef";
import { serverTimestamp, set } from "firebase/database";

export const updateTypingStatus = (
  conversationId: string,
  userId: string,
  isTyping: boolean
) => {
  const typingStatusDatabaseRef = getTypingRef(conversationId, userId);
  set(typingStatusDatabaseRef, {
    typing: isTyping,
    last_changed: serverTimestamp(),
  });
};
