import React, { useEffect, useState } from "react";
import { getMessagesUnreadCountByUser } from "../../services/firestore/getMessagesUnreadCountByUser";

export const IssueUnreadChatBadge = ({ conversationId }: { conversationId: string }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const unsubscribe = getMessagesUnreadCountByUser(
      conversationId,
      (unreadCount: number) => {
        setCount(unreadCount);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [conversationId]);

  return count > 0 ? (
    <div className="unreadMessagesContainer">
      <span className="unreadMessages">{count}</span>
    </div>
  ) : null;
};
