import {
  GetBookedClassesParamsType,
  GetBookedClassesType,
} from "../types/classes.type"
import { api } from "./base.api"

export const getBookedClasses = async (
  params: GetBookedClassesParamsType
): Promise<GetBookedClassesType> => {
  const res = await api.get(`booking-slots`, {
    params: params,
  })
  return res.data
}
