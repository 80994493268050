import React, { ChangeEvent, Dispatch, Fragment, useState } from "react"
import { FormControl, Modal } from "react-bootstrap"
import InputGroup from "react-bootstrap/InputGroup"
import { useMutation, useQuery } from "react-query"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import { deleteTeacher, getTeachers } from "../../api"
import BackdropLoader from "../../common/components/BackdropLoader"
import { updateCurrentPath } from "../../store/actions/root.actions"
import { HeaderType, TeacherOrderByType } from "../../types"
import { DateFunc } from "../../utils/dateFunction"
import { abbrState, getTeacherBadge } from "../../utils/index"
import { Button } from "../button/Button"
import { ButtonGroup } from "../button/ButtonGroup"
import { Table, TableCell } from "../table"
import { TableHead } from "./../table/index"
import EditTeacherDetail from "./EditTeacherDetail"
import ViewTeacherDetail from "./ViewTeacherDetail"

export type SortType = {
  order_desc?: boolean
  order_by?: any
}

const teacherHeaders: HeaderType<TeacherOrderByType>[] = [
  {
    key: "instructor",
    label: "Instructor",
    orderBy: "name",
  },
  {
    key: "insId",
    label: "Ins ID",
    orderBy: "id",
  },
  {
    key: "city",
    label: "City",
    orderBy: "city",
  },
  {
    key: "state",
    label: "State",
    orderBy: "state",
  },
  {
    key: "classType",
    label: "Class Type",
    orderBy: "class_title",
  },
  {
    key: "email",
    label: "Email",
    orderBy: "email",
  },
  {
    key: "classes",
    label: "Classes",
    orderBy: "classes_count",
  },
  {
    key: "status",
    label: "Status",
    orderBy: "status",
  },
  {
    key: "activated",
    label: "Activated",
    orderBy: "created",
  },
  {
    key: "lasted",
    label: "Last",
    orderBy: "updated",
  },
  {
    key: "action",
    label: "Action",
  },
]

const Teacher: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch()
  dispatch(updateCurrentPath("user", "list"))

  const [showModal, setShowModal] = useState(false)
  const [viewDetail, setViewDetail] = useState(false)
  const [userId, setUserId] = useState("")
  const [userStatus, setUserStatus] = useState("")
  const [user, setUser] = useState([])
  const [searchText, setSearchText] = useState("")
  const [smShow, setSmShow] = useState(false)
  const [teacherId, setTeacherId] = useState(null)
  const [itemNumber, setItemNumber] = useState(10)
  const [selectedOption, setSelectedOption] = useState(1)

  const [sort, setSort] = useState<SortType>({
    order_by: "status",
    order_desc: true,
  })

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      "teachers",
      selectedOption,
      itemNumber,
      searchText,
      sort && sort.order_by,
      sort && sort.order_desc,
    ],
    queryFn: () =>
      getTeachers({
        page: selectedOption,
        limit: itemNumber,
        search: searchText,
        order_by: sort.order_by,
        order_desc: sort.order_desc,
      }),
  })

  const { mutate } = useMutation({
    mutationFn: deleteTeacher,
    onSuccess: (data) => {
      toast.success(data.message)
      cancelDelete()
      refetch()
    },
    onError: (err: any) => {
      if (err.response) {
        toast.error(err.response.data.message)
        cancelDelete()
      }
    },
  })

  const pages = data ? data.pages : 1
  const page = data ? data.page : 1

  const handleParentCallback = (childData: any) => {
    if (childData) {
      setShowModal(false)
    } else {
      setShowModal(false)
    }
  }

  const handleViewParentCallback = (childData: any) => {
    setViewDetail(childData)
    setUserId("")
  }

  const handleShowModal = (userId: any, userStatus: string) => {
    setShowModal(true)
    setUserId(userId)
    setUserStatus(userStatus)
  }

  const handleViewShowModal = (id: any, user: any) => {
    setViewDetail(true)
    setUserId(id)
    setUser(user)
  }

  const handleDelete = (id: any) => {
    setTeacherId(id)
    setSmShow(true)
  }

  const handleClear = () => {
    setSearchText("")
    setSelectedOption(1)
  }

  const cancelDelete = () => {
    setTeacherId(null)
    setSmShow(false)
  }

  const handleOption = () => {
    let content = []
    for (var index = 1; index <= pages; index++) {
      content.push(
        <option
          key={index}
          value={index}>
          {index}
        </option>
      )
    }
    return content
  }
  const ConfirmDelete = async () => {
    mutate(teacherId)
  }

  const handleSelectedOption = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(Number(e.target.value))
  }
  const handleSelectItem = (e: ChangeEvent<HTMLSelectElement>) => {
    setItemNumber(Number(e.target.value))
    setSelectedOption(1)
  }

  const handlePrevious = (page: number) => {
    if (page <= 1) {
      setSelectedOption(1)
    } else {
      let current_page = page
      let previous_page = Number(current_page - 1)
      setSelectedOption(previous_page)
    }
  }
  const handleNext = (page: number) => {
    if (page < pages) {
      let current_page = page
      let next_page = Number(current_page) + 1
      setSelectedOption(next_page)
    }
  }

  const handleSort = (order_by?: TeacherOrderByType) => {
    if (order_by) {
      setSort((currentSort) => {
        if (currentSort.order_by === order_by)
          return { order_by, order_desc: !currentSort.order_desc }
        return { order_by, order_desc: false }
      })
    }
  }

  return (
    <Fragment>
      <ToastContainer />
      <div className='row'>
        <div
          className='col-xl-12 col-lg-12'
          style={{ padding: "0px" }}>
          <div
            className='card mb-4'
            style={{
              border: "none",
              borderRadius: "0px",
              background: "#F3F7FF",
            }}>
            <Modal
              centered
              show={smShow}
              backdrop='static'
              onHide={() => setSmShow(false)}
              aria-labelledby='example-modal-sizes-title-lg'>
              <div className='p-3'>
                <Modal.Title id='example-modal-sizes-title-sm'>
                  Are you sure to delete this teacher ?
                </Modal.Title>

                <Modal.Body>
                  <div className=' d-flex flex-row w-100  mt-2'>
                    <button
                      onClick={cancelDelete}
                      className='btn btn-danger  mr-3 btn-sm'>
                      Cancel
                    </button>
                    <button
                      onClick={ConfirmDelete}
                      className='btn btn-primary btn-sm'>
                      Confirm
                    </button>
                  </div>
                </Modal.Body>
              </div>
            </Modal>
            <div
              className='card-body'
              style={{ padding: "0px" }}>
              <div
                className='d-flex mb-2'
                style={{ marginTop: "30px" }}>
                <div
                  style={{
                    //  marginLeft: "20px",
                    marginLeft: "1%",
                  }}>
                  <InputGroup
                    className='searchbar'
                    style={{
                      height: "51px",
                      width: "445px",
                      background: "#FFFFFF",
                      boxShadow: "-10px 1px 53px 7px rgba(27, 30, 123, 0.1)",
                      borderRadius: "10px",
                    }}>
                    <FormControl
                      value={searchText}
                      onChange={(e) => {
                        setSelectedOption(1)
                        setSearchText(e.target.value)
                      }}
                      id='inlineFormInputGroup'
                      placeholder='Search Instructor'
                      style={{ height: "100%", border: "none" }}
                    />
                    <InputGroup.Text
                      style={{
                        color: "#807CD6",
                        background: "#DDE9FF",
                        width: "56px",
                        border: "none",
                      }}>
                      <img
                        src='/search.png'
                        alt=''
                        style={{
                          width: "24px",
                          height: "24px",
                          marginLeft: "5px",
                        }}
                      />
                    </InputGroup.Text>
                    {searchText !== "" && (
                      <Button onClick={handleClear}>Clear</Button>
                    )}
                  </InputGroup>
                </div>

                <div
                  className='ms-auto'
                  style={{ paddingTop: "30px", paddingRight: "1.5%" }}>
                  <span
                    style={{
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "160%",
                      color: "#817EB7",
                    }}
                    className='totaluser'>
                    Total instructors
                  </span>
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "160%",
                      color: "#5D59B4",
                      paddingLeft: "20px",
                    }}>
                    {data ? data.count : 0}
                  </span>
                </div>
              </div>

              <div
                className='table-responsive portlet'
                style={{
                  borderRadius: "10px",
                  boxShadow: "-10px 1px 53px 7px rgba(27, 30, 123, 0.1)",
                  margin: "auto",
                  width: "98%",
                }}>
                <Table>
                  <thead>
                    <tr
                      style={{ height: "62px" }}
                      className='rounded-top bg-teache text-white'>
                      {teacherHeaders.map((header) => (
                        <TableHead
                          key={header.key}
                          title={header.label}
                          showIcon={!!header.orderBy}
                          onClick={() => handleSort(header.orderBy)}
                          className={header.label === "Action" && "text-center"}
                          iconClass={
                            header.orderBy === sort.order_by
                              ? sort.order_desc
                                ? "descending"
                                : "ascending"
                              : ""
                          }
                        />
                      ))}
                    </tr>
                  </thead>
                  <tbody style={{ color: "#6460F2" }}>
                    {isLoading ? (
                      <tr>
                        <td colSpan={11}>
                          <BackdropLoader />
                        </td>
                      </tr>
                    ) : data && data.data ? (
                      data.data.map((value: any, index: number) => (
                        <tr
                          key={index}
                          className='text-teache'>
                          <TableCell className='fw-bold'>
                            <p
                              style={{
                                margin: 0,
                                padding: 0,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                maxWidth: "230px",
                                height: "100%",
                                color: "#5D59B4",
                              }}>
                              <Link
                                style={{ all: "unset", cursor: "pointer" }}
                                to={`/instructor/${value.id}`}>
                                {value.name}
                              </Link>
                            </p>
                          </TableCell>
                          <TableCell>
                            <Link
                              to={`/instructor/${value.id}`}
                              className='text-teache'>
                              {value.id}
                            </Link>
                          </TableCell>
                          <TableCell>{value.city}</TableCell>
                          <TableCell>
                            {abbrState(value.state, "abbr")}
                          </TableCell>
                          <TableCell>{value.class_title}</TableCell>
                          <TableCell>{value.email}</TableCell>
                          <TableCell>
                            <Link
                              to={`/instructor/${value.id}`}
                              className='text-teache'>
                              {value.classes_count ? value.classes_count : 0}
                            </Link>
                          </TableCell>
                          <TableCell>{getTeacherBadge(value.status)}</TableCell>
                          <TableCell>{DateFunc(value.created)}</TableCell>
                          <TableCell>{DateFunc(value.updated)}</TableCell>
                          <TableCell className='text-center'>
                            <ButtonGroup
                              onDetail={() =>
                                handleViewShowModal(value.id, value)
                              }
                              onEdit={() =>
                                handleShowModal(value.id, value.status)
                              }
                              onDelete={() => handleDelete(value.id)}
                            />
                          </TableCell>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={11}
                          className='text-center'>
                          No Instructor
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <div
                className='d-flex'
                style={{ color: "#6460F2", marginTop: "30px" }}>
                <div className='p-2 flex-grow-1'>
                  <div style={{ paddingLeft: "1%" }}>
                    <label
                      className='totaluser'
                      style={{
                        marginRight: 10,
                        color: "#817EB7",
                      }}>
                      Item per page:
                    </label>
                    <select
                      value={itemNumber}
                      onChange={handleSelectItem}
                      className='classic'
                      style={{
                        paddingLeft: "16px",
                        paddingRight: "16px",
                      }}>
                      <option value='10'>10</option>
                      <option value='15'>15</option>
                      <option value='20'>20</option>
                      <option value='50'>50</option>
                    </select>
                  </div>
                </div>
                <div className='p-2'>
                  <div>
                    <label
                      className='totaluser'
                      style={{
                        marginRight: 10,
                        color: "#817EB7",
                      }}>
                      Move to:
                    </label>
                    <select
                      value={selectedOption}
                      onChange={handleSelectedOption}
                      className='classic'>
                      {handleOption()}
                    </select>
                  </div>
                </div>
                <div
                  className='totaluser'
                  style={{
                    marginRight: "1.5%",
                    // marginLeft: "20px",
                    paddingTop: "8px",
                  }}>
                  <div>
                    <label
                      style={{
                        color: "#817EB7",
                        marginRight: "20px",
                      }}>
                      {`${
                        (page - 1) * itemNumber +
                        (data && data.data ? data.data.length : 0)
                      } of ${data ? data.count : 0}`}
                    </label>
                    <button
                      onClick={() => handlePrevious(page)}
                      style={{
                        marginRight: 10,
                        background: "#DDE9FF",
                        width: "42px",
                        height: "42px",
                      }}
                      type='button'
                      className='btn btn-default btn-sm'>
                      <i
                        style={{ color: "#5D59B4" }}
                        className='fas fa-angle-left'></i>
                    </button>

                    <button
                      type='button'
                      onClick={() => handleNext(page)}
                      className='btn btn-default btn-sm'
                      style={{
                        background: "#DDE9FF",
                        width: "42px",
                        height: "42px",
                      }}>
                      <i
                        style={{ color: "#5D59B4" }}
                        className='fas fa-angle-right'></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {showModal && (
              <EditTeacherDetail
                onUpdateSuccess={() => refetch()}
                userId={userId}
                userStatus={userStatus}
                showModal={showModal}
                handleCallback={handleParentCallback}
              />
            )}
            {viewDetail && (
              <ViewTeacherDetail
                userId={userId}
                user={user}
                viewDetail={viewDetail}
                handleViewParentCallback={handleViewParentCallback}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Teacher
