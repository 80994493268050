export const fakeUser = [
  {
    name: "John Doe",
    avatar:
      "https://www.joancanto.com/wp-content/uploads/2017/04/H10B1582-Edit.jpg",
    job: "Software Engineer",
    stars: 4.7,
    review: 150,
    price: "$49.99",
    location: "New York", // Add location property for John Doe
  },
  {
    name: "Jane Smith",
    avatar:
      "https://www.joancanto.com/wp-content/uploads/2017/04/H10B1582-Edit.jpg",
    job: "Graphic Designer",
    stars: 4.2,
    review: 59,
    price: "$29.99",
    location: "London", // Add location property for Jane Smith
  },
  {
    name: "Mike Johnson",
    avatar:
      "https://www.joancanto.com/wp-content/uploads/2017/04/H10B1582-Edit.jpg",
    job: "Marketing Specialist",
    stars: 3.9,
    review: 99,
    price: "$39.99",
    location: "San Francisco", // Add location property for Mike Johnson
  },
  {
    name: "Emily Brown",
    avatar:
      "https://www.joancanto.com/wp-content/uploads/2017/04/H10B1582-Edit.jpg",
    job: "Content Writer",
    stars: 4.5,
    review: 100,
    price: "$59.99",
    location: "Toronto", // Add location property for Emily Brown
  },
  {
    name: "Alex Wilson",
    avatar:
      "https://www.joancanto.com/wp-content/uploads/2017/04/H10B1582-Edit.jpg",
    job: "Photographer",
    stars: 4.8,
    review: 60,
    price: "$79.99",
    location: "Sydney", // Add location property for Alex Wilson
  },
]
