import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import firestore from ".";

export async function getMessagesFromConversation(
  senderId: string,
  receiverId: string
) {
  const conversationsRef = collection(firestore, "conversations");
  const q = query(
    conversationsRef,
    where("creatorId", "in", [senderId, receiverId]),
    where("participantId", "in", [senderId, receiverId])
  );

  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    console.log("No matching documents.");
    return;
  }

  const conversationId = querySnapshot.docs[0].id;
  const messagesRef = collection(
    firestore,
    `conversations/${conversationId}/messages`
  );
  const messagesQuery = query(messagesRef, orderBy("timestamp"));
  const messagesSnapshot = await getDocs(messagesQuery);
  const messages = messagesSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return messages;
}
