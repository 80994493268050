import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import firestore from ".";

export const getMessages = (conversationId: string, callback: Function) => {
  const messagesRef = collection(
    firestore,
    `conversations/${conversationId}/messages`
  );

  const messagesQuery = query(messagesRef, orderBy("timestamp"));

  return onSnapshot(messagesQuery, (snapshot) => {
    const messages = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    callback(messages);
  });
};
