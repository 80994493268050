import React, { forwardRef, Ref } from "react"
import { BiCalendar, BiChevronLeft, BiSearch } from "react-icons/bi"
import { BsFilterRight } from "react-icons/bs"
import { FaBell } from "react-icons/fa"
import { HiUser } from "react-icons/hi"
import { IoMdHome } from "react-icons/io"
import { IoStar } from "react-icons/io5"
import { TbSortDescending } from "react-icons/tb"
import { fakeUser } from "../../constant/fakeData"
type MobileMockupProps = {}

export const MobileMockup = forwardRef(
  (props: MobileMockupProps, ref: Ref<HTMLCanvasElement>) => {
    return (
      <div className='mockup-container'>
        <div className='mockup-header'>
          <canvas
            ref={ref}
            className='mockup-header-preview'
          />
          <div className='overlay' />
          <div className='mockup-header-element'>
            <div className='mockup-header-element__top'>
              <div className='back-button'>
                <BiChevronLeft size={28} />
              </div>
              <div className='title-container'>
                <p className='title'>rock climbing</p>
                <p className='subtitle'>45 teacher</p>
              </div>
              <div className='menu'>
                <BsFilterRight size={30} />
              </div>
            </div>
            <div className='mockup-header-element__middle'>
              <label
                htmlFor='searchBar'
                className='search-bar'>
                <div>
                  <BiSearch size={22} />
                </div>
                <input
                  type='text'
                  id='searchBar'
                  placeholder='Find teacher...'
                />
              </label>
              <div className='filter-button'>
                <TbSortDescending size={20} />
              </div>
            </div>
            <div className='mockup-header-element__bottom'>
              <div className='private'>Private</div>
              <div className='group'>Group</div>
            </div>
          </div>
        </div>
        <div className='mockup-body'>
          {fakeUser.map((user) => (
            <UserCard {...user} />
          ))}
        </div>
        <div className='mockup-footer'>
          <div>
            <IoMdHome size={22} />
          </div>
          <div>
            <FaBell size={17} />
          </div>
          <div>
            <BiCalendar size={20} />
          </div>
          <div>
            <HiUser size={22} />
          </div>
        </div>
      </div>
    )
  }
)

const UserCard = (props: any) => {
  return (
    <div className='user-card'>
      <div className='user-card__avatar'>
        <img
          src={props.avatar}
          alt='avatar'
        />
      </div>
      <div className='user-card__infos'>
        <div className='name-container'>
          <div className='name'>{`${props.name.split(" ")[0]} ${
            props.name.split(" ")[1][0]
          }.`}</div>
          <div className='location'>{props.location}</div>
        </div>
        <div className='job'>{props.job}</div>
        <div className='review-container'>
          <div className='stars'>
            <IoStar size={16} />
            <p>{props.stars}</p>
          </div>

          <p className='review'>{props.review} reviews</p>
          <p className='price'>{props.price}</p>
        </div>
      </div>
    </div>
  )
}
