import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBinkf_bzky3TbUrXbcv6XkfRqAsbm2CsA",
  authDomain: "teache-39152.firebaseapp.com",
  databaseURL: "https://teache-39152-default-rtdb.firebaseio.com",
  projectId: "teache-39152",
  storageBucket: "teache-39152.appspot.com",
  messagingSenderId: "822655888239",
  appId: "1:822655888239:web:25e0e34919b949b962d69a",
  measurementId: "G-LG6D5L9QJK",
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
