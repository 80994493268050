import React, { ChangeEvent, Dispatch, Fragment, useState } from "react";
import { Button, FormControl } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import { AiOutlineCaretDown } from "react-icons/ai";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getIssues } from "../../api/issue.api";
import BackdropLoader from "../../common/components/BackdropLoader";
import { updateCurrentPath } from "../../store/actions/root.actions";
import { IssuesOrderByType } from "../../types";
import { DateFunc, convertDate } from "../../utils/dateFunction";
import Chat from "../Chat";
import { IssuesTeacherHeaders, IssuesUserHeaders } from "./issuesHeaderConst";
import { IssueUnreadChatBadge } from "./IssueUnreadChatBadge";

export type SortType = {
  order_desc?: boolean;
  order_by?: IssuesOrderByType;
};

let btnClass = {
  borderRadius: "8px",
  marginLeft: "3px",
  background: "#6460F2",
  border: "none",
};

let btnClass2 = {
  borderRadius: "8px",
  marginLeft: "3px",
  background: "white",
  border: "none",
  color: "#6460F2",
};

type InitiatorType = "teacher" | "user";
type IssuesStatusType = "opened" | "solved";

const getStorageValue = (key: string, defaultValue: any) => {
  const storedData = sessionStorage.getItem(key);
  if (storedData) return storedData;
  return defaultValue;
};

const Issues: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();

  dispatch(updateCurrentPath("user", "list"));

  const [showModal, setShowModal] = useState(false);
  const [initiator, setInitiator] = useState<InitiatorType>(
    getStorageValue("initiator", "user")
  );
  const [userId, setUserId] = useState<number>();
  const [teacherId, setTeacherId] = useState<number>();
  const [classId, setClassId] = useState<number>();
  const [userName, setUserName] = useState("");
  const [teacherName, setTeacherName] = useState("");
  const [issueStatus, setIssueStatus] = useState<IssuesStatusType>(
    getStorageValue("status", "opened")
  );
  const [searchText, setSearchText] = useState("");
  const [selectedOption, setSelectedOption] = useState(
    Number(getStorageValue("page", 0))
  );
  const [itemNumber, setItemNumber] = useState(10);

  const [sort, setSort] = useState<SortType>({
    order_desc: true,
  });

  const { data, isLoading } = useQuery({
    queryKey: [
      "issues",
      selectedOption,
      itemNumber,
      searchText,
      sort.order_by,
      sort.order_desc,
      issueStatus,
      initiator,
    ],
    queryFn: () =>
      getIssues({
        page: selectedOption,
        limit: itemNumber,
        search: searchText,
        order_by: sort.order_by,
        order_desc: sort.order_desc,
        solved: issueStatus,
        initiator,
      }),
  });

  const handleInitiator = (status: InitiatorType) => {
    setInitiator(status);
    setSelectedOption(0);
    sessionStorage.setItem("initiator", status);
    sessionStorage.setItem("page", "0");
  };
  const handleBtnStatus = (status: IssuesStatusType) => {
    setIssueStatus(status);
    sessionStorage.setItem("status", status);
  };

  const handleParentCallback = (childData: any) => {
    setShowModal(childData);
  };

  const handleShowModal = (
    id: number,
    user_id: number,
    teacher_id: number,
    user_name: string,
    teacher_name: string
  ) => {
    setShowModal(true);
    setUserId(user_id);
    setTeacherId(teacher_id);
    setClassId(id);
    setUserName(user_name);
    setTeacherName(teacher_name);
  };

  const handleClear = () => {
    setSearchText("");
    setSelectedOption(0);
  };

  const pages = data ? data.pages : 1;
  const page = data ? data.page : 1;

  const handlePrevious = (page: number) => {
    if (page <= 1) {
      setSelectedOption(1);
    } else {
      let current_page = page;
      let previous_page = Number(current_page - 1);
      setSelectedOption(previous_page);
      sessionStorage.setItem("page", `${previous_page}`);
    }
  };
  const handleNext = (page: number) => {
    if (page < pages) {
      let current_page = page;
      let next_page = Number(current_page) + 1;
      setSelectedOption(next_page);
      sessionStorage.setItem("page", `${next_page}`);
    }
  };

  const handleOption = () => {
    let content = [];

    for (var index = 1; index <= pages; index++) {
      content.push(
        <option key={index} value={index}>
          {index}
        </option>
      );
    }
    return content;
  };
  const handleSelectedOption = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(Number(e.target.value));
    sessionStorage.setItem("page", e.target.value);
  };
  const handleSelectItem = (e: ChangeEvent<HTMLSelectElement>) => {
    setItemNumber(Number(e.target.value));
    setSelectedOption(1);
    sessionStorage.setItem("page", "1");
  };

  const handleSort = (order_by?: IssuesOrderByType) => {
    if (order_by) {
      setSort((currentSort) => {
        if (currentSort.order_by === order_by)
          return { order_by, order_desc: !currentSort.order_desc };
        return { order_by, order_desc: false };
      });
    }
  };
  const IssuesHeaders =
    initiator === "teacher" ? IssuesTeacherHeaders : IssuesUserHeaders;
  return (
    <Fragment>
      {isLoading && <BackdropLoader />}
      <div className="row">
        <div className="col-xl-12 col-lg-12" style={{ padding: "0px" }}>
          <div
            className="card mb-4"
            style={{
              border: "none",
              borderRadius: "0px",
              background: "#F3F7FF",
            }}
          >
            <div className="card-body" style={{ padding: "0px" }}>
              <div className="d-flex mb-2" style={{ marginTop: "30px" }}>
                <div style={{ marginLeft: "1%" }}>
                  <ToggleButtonGroup
                    type="radio"
                    name="options1"
                    defaultValue={1}
                    style={{
                      paddingTop: "10px",
                      paddingRight: "4px",
                      borderRadius: "10px",
                      boxShadow: "-10px 1px 53px 7px rgba(27, 30, 123, 0.1)",
                      background: "white",
                    }}
                  >
                    <ToggleButton
                      id="user"
                      value={"user"}
                      style={initiator === "user" ? btnClass : btnClass2}
                      onClick={() => handleInitiator("user")}
                    >
                      <i
                        className="fas fa-users"
                        style={{ marginRight: "8px" }}
                      ></i>
                      Users
                    </ToggleButton>
                    <ToggleButton
                      id="teacher"
                      value={"teacher"}
                      style={initiator === "teacher" ? btnClass : btnClass2}
                      onClick={() => handleInitiator("teacher")}
                    >
                      <i
                        className="fas fa-briefcase"
                        style={{ marginRight: "8px" }}
                      ></i>
                      Instructors
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <div
                  style={{
                    marginLeft: "auto",
                    // marginRight: "auto",
                  }}
                >
                  <InputGroup
                    className="searchbar"
                    style={{
                      height: "51px",
                      width: "445px",
                      background: "#FFFFFF",
                      boxShadow: "-10px 1px 53px 7px rgba(27, 30, 123, 0.1)",
                      borderRadius: "10px",
                    }}
                  >
                    <FormControl
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      id="inlineFormInputGroup"
                      placeholder={
                        initiator === "teacher"
                          ? "Search Instructor"
                          : "Search User"
                      }
                      style={{ height: "100%", border: "none" }}
                    />
                    <InputGroup.Text
                      style={{
                        color: "#807CD6",
                        background: "#DDE9FF",
                        width: "56px",
                        border: "none",
                      }}
                    >
                      <img
                        src="/search.png"
                        alt=""
                        style={{
                          width: "24px",
                          height: "24px",
                          marginLeft: "5px",
                        }}
                      />
                    </InputGroup.Text>
                    {searchText !== "" && (
                      <button
                        className="btn btn-secondary btn-sm "
                        onClick={handleClear}
                      >
                        Clear
                      </button>
                    )}
                  </InputGroup>
                </div>

                <div
                  className="ms-auto"
                  style={{ paddingBottom: "20px", paddingRight: "1.5%" }}
                >
                  <ToggleButtonGroup
                    type="radio"
                    name="options2"
                    defaultValue={1}
                    style={{
                      paddingTop: "10px",
                      paddingRight: "4px",
                      borderRadius: "10px",
                      boxShadow: "-10px 1px 53px 7px rgba(27, 30, 123, 0.1)",
                      background: "white",
                    }}
                  >
                    <ToggleButton
                      id="tbg-radio-3"
                      value={1}
                      style={
                        issueStatus === "opened"
                          ? {
                              ...btnClass,
                              background: "#FFD6D2",
                              color: "#A8433A",
                            }
                          : { ...btnClass2 }
                      }
                      onClick={() => handleBtnStatus("opened")}
                    >
                      Active
                    </ToggleButton>
                    <ToggleButton
                      id="tbg-radio-4"
                      value={2}
                      style={
                        issueStatus === "solved"
                          ? {
                              ...btnClass,
                              background: "#B8F4DB",
                              color: "#246B4D",
                            }
                          : { ...btnClass2 }
                      }
                      onClick={() => handleBtnStatus("solved")}
                    >
                      Closed
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
              <div
                className="table-responsive portlet"
                style={{
                  borderRadius: "10px",
                  boxShadow: "-10px 1px 53px 7px rgba(27, 30, 123, 0.1)",
                  margin: "auto",
                  width: "98%",
                }}
              >
                <table className="table">
                  <thead>
                    <tr
                      style={{
                        height: "62px",
                      }}
                      className="rounded-top bg-teache text-white"
                    >
                      {IssuesHeaders.map((header) => (
                        <th
                          key={header.key}
                          scope="col"
                          className={`px-3 align-middle text-nowrap position-relative ${header.className}`}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleSort(header.orderBy)}
                        >
                          {header.label}

                          {header.orderBy && (
                            <AiOutlineCaretDown
                              style={{ marginTop: "4.5px" }}
                              className={`position-absolute ${
                                header.orderBy === sort.order_by
                                  ? sort.order_desc
                                    ? "descending"
                                    : "ascending"
                                  : ""
                              }`}
                            />
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data && data.data ? (
                      data.data.map((item) => (
                        <tr key={item.id} className="text-teache">
                          <td className="px-3 align-middle text-nowrap text-center">
                            <Link
                              to={`/cancelledclasses/${item.class_id}`}
                              className="text-teache"
                            >
                              {item.class_id}
                            </Link>
                          </td>
                          <td className="px-3 align-middle text-nowrap fw-bolder">
                            <p
                              style={{
                                margin: 0,
                                padding: 0,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                maxWidth: "230px",
                                height: "100%",
                                color: "#5D59B4",
                              }}
                            >
                              {initiator === "teacher" ? (
                                <Link
                                  style={{ all: "unset", cursor: "pointer" }}
                                  to={`/instructor/${item.teacher_id}`}
                                >
                                  {item.teacher_name}
                                </Link>
                              ) : (
                                <Link
                                  style={{ all: "unset", cursor: "pointer" }}
                                  to={`/users/${item.student_user_id}`}
                                >
                                  {item.student_name}
                                </Link>
                              )}
                            </p>
                          </td>

                          <td className="px-3 align-middle text-nowrap">
                            <p
                              style={{
                                margin: 0,
                                padding: 0,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                maxWidth: "230px",
                                height: "100%",
                              }}
                            >
                              {initiator === "teacher" ? (
                                <Link
                                  style={{ all: "unset", cursor: "pointer" }}
                                  to={`/users/${item.student_user_id}`}
                                >
                                  {item.student_name}
                                </Link>
                              ) : (
                                <Link
                                  style={{ all: "unset", cursor: "pointer" }}
                                  to={`/instructor/${item.teacher_id}`}
                                >
                                  {item.teacher_name}
                                </Link>
                              )}
                            </p>
                          </td>
                          <td className="px-3 align-middle text-nowrap">
                            {convertDate(item.class_date)}
                          </td>
                          <td className="px-3 align-middle text-nowrap text-center">
                            {item.payment}
                          </td>
                          <td className="px-3 align-middle text-nowrap text-center">
                            {item.cost}
                          </td>
                          <td className="px-3 align-middle text-nowrap">
                            {DateFunc(item.created)}
                          </td>
                          <td className="px-3 align-middle text-nowrap ">
                            <Button
                              onClick={() =>
                                handleShowModal(
                                  item.class_id,
                                  item.student_user_id,
                                  item.teacher_user_id,
                                  item.student_name,
                                  item.teacher_name
                                )
                              }
                              style={{
                                all: "unset",
                                color: "#817EB7",
                              }}
                            >
                              Chat
                            </Button>
                          </td>
                          <td className="px-3 align-middle text-nowrap ">
                            <Link
                              to={{
                                pathname: `/issues/${item.id}`,
                                state: {
                                  issueInfo: item,
                                  issuesStatus: issueStatus,
                                  side: initiator,
                                },
                              }}
                              style={{
                                color: "#817EB7",
                                textAlign: "left",
                                margin: "auto",
                                position: "relative",
                              }}
                            >
                              Issue{" "}
                              <IssueUnreadChatBadge conversationId={item.firestore_conversation_id} />
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={9} className="text-center">
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {showModal && (
                <Chat
                  side={initiator}
                  classId={classId}
                  userId={userId}
                  teacherId={teacherId}
                  userName={userName}
                  teacherName={teacherName}
                  showModal={showModal}
                  handleCallback={handleParentCallback}
                />
              )}
              <div
                className="d-flex"
                style={{ color: "#6460F2", marginTop: "30px" }}
              >
                <div className="p-2 flex-grow-1">
                  <div style={{ paddingLeft: "1%" }}>
                    <label
                      className="totaluser"
                      style={{ marginRight: 10, color: "#817EB7" }}
                    >
                      Item per page:
                    </label>
                    <select
                      value={selectedOption}
                      onChange={(e) => handleSelectItem(e)}
                      className="classic"
                      style={{
                        paddingLeft: "16px",
                        paddingRight: "16px",
                      }}
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                </div>
                <div className="p-2">
                  <div>
                    <label
                      className="totaluser"
                      style={{ marginRight: 10, color: "#817EB7" }}
                    >
                      Move to:
                    </label>
                    <select
                      value={selectedOption}
                      onChange={(e) => handleSelectedOption(e)}
                      className="classic"
                      style={{
                        paddingLeft: "16px",
                        paddingRight: "16px",
                      }}
                    >
                      {handleOption()}
                    </select>
                  </div>
                </div>
                <div
                  style={{
                    marginRight: "1.5%",
                    // marginLeft: "20px",
                    paddingTop: "8px",
                  }}
                >
                  <div>
                    <label
                      className="totaluser"
                      style={{ color: "#817EB7", marginRight: "20px" }}
                    >
                      {(page - 1) * itemNumber +
                        (data && data.data ? data.data.length : 0)}{" "}
                      of {data ? data.count : 0}
                    </label>
                    <button
                      style={{
                        marginRight: 10,
                        background: "#DDE9FF",
                        width: "42px",
                        height: "42px",
                      }}
                      onClick={() => handlePrevious(page)}
                      type="button"
                      className="btn btn-default btn-sm"
                    >
                      <i
                        style={{ color: "#5D59B4" }}
                        className="fas fa-angle-left"
                      ></i>
                    </button>

                    <button
                      type="button"
                      onClick={() => handleNext(page)}
                      className="btn btn-default btn-sm"
                      style={{
                        background: "#DDE9FF",
                        width: "42px",
                        height: "42px",
                      }}
                    >
                      <i
                        style={{ color: "#5D59B4" }}
                        className="fas fa-angle-right"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Issues;
