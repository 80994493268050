import React, { Dispatch, Fragment, useEffect, useMemo, useState } from "react"
import { FormControl, Modal } from "react-bootstrap"
import InputGroup from "react-bootstrap/InputGroup"
import { AiOutlineCaretDown } from "react-icons/ai"
import { useDispatch } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import BaseUrl from "../../BaseUrl/BaseUrl"
import BackdropLoader from "../../common/components/BackdropLoader"
import { updateCurrentPath } from "../../store/actions/root.actions"
import { abbrState, getTeacherBadge } from "../../utils"
import EditTeacherDetail from "../Teacher/EditTeacherDetail"
import ViewTeacherDetail from "../Teacher/ViewTeacherDetail"
import { ButtonGroup } from "../button/ButtonGroup"

const Classinfo: React.FC = () => {
  let pathname = useLocation().pathname
  let temp = pathname.slice(1).split("/")
  const id = Number(temp[1])
  const dispatch: Dispatch<any> = useDispatch()
  dispatch(updateCurrentPath("user", "list"))
  const [showModal, setShowModal] = useState(false)
  const [viewDetail, setViewDetail] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [teacherId, setTeacherId] = useState("")
  const [teacherStatus, setTeacherStatus] = useState("")
  const [detail, setDetail] = useState()
  const [searchText, setSearchText] = useState("")
  const [classData, setClassData] = useState([])
  const [totalCount, setTotalCount] = useState(1)
  const [selectedOption, setSelectedOption] = useState(1)
  const [showerCount, setShowerCount] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [pagesNumber, setPagesNumber] = useState(1)
  const [itemNumber, setItemNumber] = useState(10)
  const [loader, setLoader] = useState(false)

  const handleParentCallback = (childData: any) => {
    if (childData) {
      setShowModal(false)
    } else {
      setShowModal(false)
    }
  }

  const handleViewParentCallback = (childData: any) => {
    setViewDetail(childData)
  }

  const handleShowModal = (id: any, status: string) => {
    setShowModal(true)
    setTeacherId(id)
    setTeacherStatus(status)
  }

  const handleViewShowModal = (id: any, item: any) => {
    setViewDetail(true)
    setTeacherId(id)
    setDetail(item)
  }

  const handleDelete = (id: any) => {
    setDeleteModal(true)
    setTeacherId(id)
  }

  const cancelDelete = () => {
    setTeacherId(null)
    setDeleteModal(false)
  }

  const ConfirmDelete = async () => {
    setLoader(true)
    const axiosConfig: any = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("teache_token")}`,
      },
    }

    BaseUrl.delete(`/delete-teacher/${teacherId}`, axiosConfig)
      .then((res) => {
        toast.success(res.data.message)
        cancelDelete()
        setLoader(false)
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message)
          cancelDelete()
          setLoader(false)
        }
      })
  }

  useEffect(() => {
    const axiosConfig: any = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("teache_token")}`,
      },
    }
    BaseUrl.get(
      `/class-type-teachers/${id}?limit=${itemNumber}&page=${selectedOption}&search=${searchText}`,
      axiosConfig
    ).then((res) => {
      if (res.status === 200) {
        if (res.data) {
          setTotalCount(res.data.count)
          setClassData(res.data.data)
          setPageNumber(res.data.page)
          setPagesNumber(res.data.pages)
          if (res.data.data) {
            setShowerCount(res.data.data.length)
          }
          console.log(res.data.data)
        } else {
          setClassData([])
          setTotalCount(0)
        }
      } else {
        setClassData([])
        setTotalCount(0)
      }
    })
  }, [id, itemNumber, showModal, searchText, selectedOption])

  const handleClear = () => {
    setSearchText("")
    setSelectedOption(1)
  }

  const handlePrevious = (page: any) => {
    if (page <= 1) {
      page = 1
      setSelectedOption(page)
    } else {
      let current_page = page
      let previous_page = Number(current_page - 1)
      setSelectedOption(previous_page)
    }
  }
  const handleNext = (page: any) => {
    if (page < pagesNumber) {
      let current_page = page
      let next_page = Number(current_page) + 1
      setSelectedOption(next_page)
    }
  }

  const handleOption = () => {
    let content = []
    for (var index = 1; index <= pagesNumber; index++) {
      content.push(
        <option
          key={index}
          value={index}>
          {index}
        </option>
      )
    }
    return content
  }
  const handleSelectedOption = (e: any) => {
    setSelectedOption(e.target.value)
  }
  const handleSelectItem = (e: any) => {
    setItemNumber(e.target.value)
    setSelectedOption(1)
  }

  const useSortableData = (teacherItems, config = null) => {
    const [sortConfig, setSortConfig] = useState(config)

    const sortedItems = useMemo(() => {
      if (teacherItems) {
        let sortableItems = [...teacherItems]
        if (sortConfig !== null) {
          sortableItems.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
              return sortConfig.direction === "ascending" ? -1 : 1
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
              return sortConfig.direction === "ascending" ? 1 : -1
            }
            return 0
          })
        }
        return sortableItems
      }
    }, [teacherItems, sortConfig])

    const requestSort = (key) => {
      let direction = "ascending"
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending"
      }
      setSortConfig({ key, direction })
    }

    return { teacherItems: sortedItems, requestSort, sortConfig }
  }

  const { teacherItems, requestSort, sortConfig } = useSortableData(classData)

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return
    }
    return sortConfig.key === name ? sortConfig.direction : undefined
  }

  return (
    <Fragment>
      {loader && <BackdropLoader />}
      <ToastContainer />
      <div className='row'>
        <div
          className='col-xl-12 col-lg-12'
          style={{ padding: "0px" }}>
          <div
            className='card mb-4'
            style={{
              border: "none",
              borderRadius: "0px",
              background: "#F3F7FF",
            }}>
            <Modal
              centered
              show={deleteModal}
              backdrop='static'
              onHide={() => setDeleteModal(false)}
              aria-labelledby='example-modal-sizes-title-lg'>
              <div className='p-3'>
                <Modal.Title id='example-modal-sizes-title-sm'>
                  Are you sure to delete this class ?
                </Modal.Title>

                <Modal.Body>
                  <div className=' d-flex flex-row w-100  mt-2'>
                    <button
                      onClick={cancelDelete}
                      className='btn btn-danger  mr-3 btn-sm'>
                      Cancel
                    </button>
                    <button
                      onClick={ConfirmDelete}
                      className='btn btn-primary btn-sm'>
                      Confirm
                    </button>
                  </div>
                </Modal.Body>
              </div>
            </Modal>
            <div
              className='card-body'
              style={{ padding: "0px" }}>
              <div
                className='d-flex mb-2'
                style={{ marginTop: "30px" }}>
                <div
                  style={{
                    paddingTop: "30px",
                    paddingRight: "1.5%",
                    marginLeft: "2%",
                  }}>
                  <span
                    className='totaluser'
                    style={{
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "160%",
                      color: "#817EB7",
                    }}>
                    ID:
                  </span>
                  <span
                    className='totaluser'
                    style={{
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "160%",
                      color: "#5D59B4",
                      paddingLeft: "10px",
                    }}>
                    {id}
                  </span>
                </div>
                <div
                  style={{
                    marginLeft: "auto",
                    // marginRight: "auto",
                  }}>
                  <InputGroup
                    className='searchbar'
                    style={{
                      height: "51px",
                      width: "445px",
                      background: "#FFFFFF",
                      boxShadow: "-10px 1px 53px 7px rgba(27, 30, 123, 0.1)",
                      borderRadius: "10px",
                    }}>
                    <FormControl
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      id='inlineFormInputGroup'
                      placeholder='Search User'
                      style={{ height: "100%", border: "none" }}
                    />
                    <InputGroup.Text
                      style={{
                        color: "#807CD6",
                        background: "#DDE9FF",
                        width: "56px",
                        border: "none",
                      }}>
                      {/* <i
                          style={{ width: "24px", height: "18px" }}
                          className="fa fa-search"
                        ></i> */}
                      <img
                        src='/search.png'
                        alt=''
                        style={{
                          width: "24px",
                          height: "24px",
                          marginLeft: "5px",
                        }}
                      />
                    </InputGroup.Text>
                    {searchText !== "" && (
                      <button
                        style={{ marginRight: 5 }}
                        className='btn btn-secondary btn-sm '
                        onClick={handleClear}>
                        Clear
                      </button>
                    )}
                  </InputGroup>
                </div>

                <div
                  className='ms-auto'
                  style={{ paddingTop: "30px", paddingRight: "1.5%" }}>
                  <span
                    className='totaluser'
                    style={{
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "160%",
                      color: "#817EB7",
                    }}>
                    Total Instructors
                  </span>
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "160%",
                      color: "#5D59B4",
                      paddingLeft: "20px",
                    }}>
                    {totalCount}
                  </span>
                </div>
              </div>
              {showModal && (
                <EditTeacherDetail
                  userId={teacherId}
                  userStatus={teacherStatus}
                  showModal={showModal}
                  handleCallback={handleParentCallback}
                />
              )}
              {viewDetail && (
                <ViewTeacherDetail
                  detail={detail}
                  userId={teacherId}
                  viewDetail={viewDetail}
                  handleViewParentCallback={handleViewParentCallback}
                />
              )}
              <div
                className='table-responsive portlet'
                style={{
                  borderRadius: "10px",
                  boxShadow: "-10px 1px 53px 7px rgba(27, 30, 123, 0.1)",
                  margin: "auto",
                  width: "98%",
                }}>
                <table className='table'>
                  <thead>
                    <tr
                      style={{
                        backgroundColor: "#6460F2",
                        color: "white",
                        height: "62px",
                      }}
                      className='rounded-top'>
                      {/* <th scope="col">#</th> */}
                      <th
                        scope='col'
                        style={{
                          paddingLeft: 15 + "px",
                          verticalAlign: "middle",
                        }}>
                        Instructor
                        <AiOutlineCaretDown
                          color='#BFBDFF'
                          onClick={() => requestSort("name")}
                          className={getClassNamesFor("name")}
                        />
                      </th>
                      <th
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}>
                        Ins ID
                        <AiOutlineCaretDown
                          color='#BFBDFF'
                          onClick={() => requestSort("id")}
                          className={getClassNamesFor("id")}
                        />
                      </th>
                      <th
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}>
                        City
                        <AiOutlineCaretDown
                          color='#BFBDFF'
                          onClick={() => requestSort("city")}
                          className={getClassNamesFor("city")}
                        />
                      </th>
                      <th
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}>
                        State
                        <AiOutlineCaretDown
                          color='#BFBDFF'
                          onClick={() => requestSort("state")}
                          className={getClassNamesFor("state")}
                        />
                      </th>
                      <th
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}>
                        Email
                        <AiOutlineCaretDown
                          color='#BFBDFF'
                          onClick={() => requestSort("email")}
                          className={getClassNamesFor("email")}
                        />
                      </th>
                      <th
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}>
                        Classes
                        <AiOutlineCaretDown
                          color='#BFBDFF'
                          onClick={() => requestSort("classes_count")}
                          className={getClassNamesFor("classes_count")}
                        />
                      </th>
                      <th
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}>
                        Rvenue
                        <AiOutlineCaretDown
                          color='#BFBDFF'
                          onClick={() => requestSort("revenue")}
                          className={getClassNamesFor("revenue")}
                        />
                      </th>
                      <th
                        scope='col'
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}>
                        Status
                        <AiOutlineCaretDown
                          color='#BFBDFF'
                          onClick={() => requestSort("status")}
                          className={getClassNamesFor("status")}
                        />
                      </th>
                      <th
                        scope='col'
                        style={{ verticalAlign: "middle" }}
                        className='text-center'>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ color: "#6460F2" }}>
                    {teacherItems ? (
                      teacherItems.length > 0 &&
                      teacherItems.map((item: any, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              paddingLeft: 15 + "px",
                              fontWeight: 600,
                              lineHeight: "160%",
                              color: "#5D59B4",
                            }}>
                            <Link
                              style={{ all: "unset", cursor: "pointer" }}
                              to={`/instructor/${item.id}`}>
                              {item.name}
                            </Link>
                          </td>
                          <td
                            style={{
                              paddingLeft: 15 + "px",
                              textAlign: "center",
                              color: "#5D59B4",
                            }}>
                            <Link
                              style={{ all: "unset", cursor: "pointer" }}
                              to={`/instructor/${item.id}`}>
                              {item.id}
                            </Link>
                          </td>

                          <td style={{ color: "#817EB7", textAlign: "center" }}>
                            {item.city}
                          </td>
                          <td style={{ color: "#817EB7", textAlign: "center" }}>
                            {abbrState(item.state, "abbr")}
                          </td>
                          <td style={{ color: "#817EB7", textAlign: "center" }}>
                            {item.email}
                          </td>
                          <td style={{ color: "#817EB7", textAlign: "center" }}>
                            <Link
                              to={`/instructor/${item.id}`}
                              style={{ color: "#817EB7" }}>
                              {item.classes_count}
                            </Link>
                          </td>
                          <td
                            style={{
                              color: "#817EB7",
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}>
                            ${item.revenue}
                          </td>
                          <td
                            style={{
                              color: "#817EB7",
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}>
                            {getTeacherBadge(item.status)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <ButtonGroup
                              onDetail={() =>
                                handleViewShowModal(item.id, item)
                              }
                              onEdit={() =>
                                handleShowModal(item.id, item.status)
                              }
                              onDelete={() => handleDelete(item.id)}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={9}
                          className='text-center'>
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className='d-flex'
                style={{ color: "#6460F2", marginTop: "30px" }}>
                <div className='p-2 flex-grow-1'>
                  <div style={{ paddingLeft: "1%" }}>
                    <label
                      className='totaluser'
                      style={{ marginRight: 10, color: "#817EB7" }}>
                      Item per page:
                    </label>
                    <select
                      value={selectedOption}
                      onChange={(e) => handleSelectItem(e)}
                      className='classic'
                      style={{
                        paddingLeft: "16px",
                        paddingRight: "16px",
                      }}>
                      <option value='10'>10</option>
                      <option value='15'>15</option>
                      <option value='20'>20</option>
                      <option value='50'>50</option>
                    </select>
                  </div>
                </div>
                <div className='p-2'>
                  <div>
                    <label
                      className='totaluser'
                      style={{ marginRight: 10, color: "#817EB7" }}>
                      Move to:
                    </label>
                    <select
                      value={selectedOption}
                      onChange={(e) => handleSelectedOption(e)}
                      className='classic'
                      style={{
                        paddingLeft: "16px",
                        paddingRight: "16px",
                      }}>
                      {handleOption()}
                    </select>
                  </div>
                </div>
                <div
                  style={{
                    marginRight: "1.5%",
                    // marginLeft: "20px",
                    paddingTop: "8px",
                  }}>
                  <div>
                    <label
                      className='totaluser'
                      style={{ color: "#817EB7", marginRight: "20px" }}>
                      {(pageNumber - 1) * itemNumber + showerCount} of{" "}
                      {totalCount}
                    </label>
                    <button
                      style={{
                        marginRight: 10,
                        background: "#DDE9FF",
                        width: "42px",
                        height: "42px",
                      }}
                      type='button'
                      className='btn btn-default btn-sm'
                      onClick={() => handlePrevious(pageNumber)}>
                      <i
                        style={{ color: "#5D59B4" }}
                        className='fas fa-angle-left'></i>
                    </button>

                    <button
                      type='button'
                      className='btn btn-default btn-sm'
                      onClick={() => handleNext(pageNumber)}
                      style={{
                        background: "#DDE9FF",
                        width: "42px",
                        height: "42px",
                      }}>
                      <i
                        style={{ color: "#5D59B4" }}
                        className='fas fa-angle-right'></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Classinfo
