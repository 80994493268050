export const DateFunc = (val: string) => {
  const formatedDate = new Date(parseInt(val)).toLocaleString("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  })
  let dateType = formatedDate.replaceAll("/", "-")
  return dateType
}

export const convertToPmAm = (time: string) => {
  if (time) {
    const [hours, minuts] = time.split(":")
    const pmAm = parseInt(hours) > 12 ? "PM" : "AM"
    const formatHours = parseInt(hours) % 12 === 0 ? 12 : parseInt(hours) % 12
    return `${formatHours}:${minuts} ${pmAm}`
  }
  return ""
}

// convert Date from YYYY/MM/DD to MM/DD/YYYY
export const convertDate = (date: string) => {
  if (!date) return ""
  var dateParts = date.split("-")
  return `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`
}
