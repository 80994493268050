import { GetIssuesParamsType, GetIssuesType } from "../types"
import { api } from "./base.api"

export const getIssues = async (
  params: GetIssuesParamsType
): Promise<GetIssuesType> => {
  const res = await api.get(`/issues`, {
    params: params,
  })
  return res.data
}
