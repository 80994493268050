import { GetUsersParamsType, GetUsersType } from "../types"
import { api } from "./base.api"

export const getUsers = async (
  params: GetUsersParamsType
): Promise<GetUsersType> => {
  const res = await api.get(`/users`, {
    params: params,
  })
  return res.data
}

export const deleteUser = async (userId: string) => {
  const res = await api.delete(`/delete-user/${userId}`)
  return res.data
}
