import React, { useRef, useState } from "react"
import { Modal } from "react-bootstrap"
import ReactCrop, {
  Crop,
  PixelCrop,
  centerCrop,
  makeAspectCrop,
} from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import { MobileMockup } from "./components"
import "./styles/style.css"
import { canvasPreview, useDebounceEffect } from "./utils"

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}
type ImagePreviewProps = {
  onSave?: (picture: any) => void
}
export const ImagePreview = ({ onSave }: ImagePreviewProps) => {
  const [show, setShow] = useState(false)
  const [imgSrc, setImgSrc] = useState("")
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined)
      const reader = new FileReader()
      reader.addEventListener("load", () => {
        if (reader.result) setImgSrc(reader.result.toString() || "")
      })
      reader.readAsDataURL(e.target.files[0])
    }
    setShow(true)
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget
    setCrop(centerAspectCrop(width, height, 16 / 9))
  }

  function handleSave() {
    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist")
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob")
      }

      if (onSave) onSave(blob)
      setShow(false)
    })
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop &&
        completedCrop.width &&
        completedCrop.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop)
      }
    },
    100,
    [completedCrop]
  )

  return (
    <>
      <input
        name='imageSelector'
        id='imageSelector'
        hidden
        type='file'
        accept='image/*'
        onChange={onSelectFile}
      />
      <label
        htmlFor='imageSelector'
        className='image-label'>
        Add Picture
      </label>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        aria-labelledby='contained-modal-title-vcenter'>
        <div className='image-preview-container'>
          <div className='image-crop-container'>
            {!!completedCrop && <MobileMockup ref={previewCanvasRef} />}
            <div className='crop-container'>
              {!!imgSrc && (
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={16 / 9}>
                  <img
                    ref={imgRef}
                    alt='Crop me'
                    src={imgSrc}
                    className='image-to-crop'
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              )}
            </div>
          </div>
          <button
            onClick={handleSave}
            className='save-button'>
            save
          </button>
        </div>
      </Modal>
    </>
  )
}
