import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { convertTimestamp } from "../../services/utils/convertTimestamp";
import { getMessagesFromConversation } from "../../services/firestore/getMessagesFromConversation";
import { getAuth } from "firebase/auth";
import firebaseApp from "../../services/firebase";

export default function Chat(props: any) {
  const [show] = useState(props.showModal);
  const [messages, setMessages] = useState<any[]>([]);

  let dateStatus = "";
  const date = { today: new Date() };
  let todayDate = moment(date.today).format("LL");

  useEffect(() => {
    getMessagesFromConversation(
      props.userId.toString(),
      props.teacherId.toString()
    )
      .then((result) => {
        setMessages(
          result?.map((message: any) => ({
            _id: message.id,
            text: message.text,
            createdAt: convertTimestamp(message.timestamp),
            user: { _id: message.senderId },
          })) || []
        );
      })
      .catch((error) => {
        console.log(error);
        if (error.toString().includes("Missing or insufficient permissions")) {
          const auth = getAuth(firebaseApp);
          auth.signOut().catch((error) => console.log(error));
          localStorage.removeItem("teache_token");
          window.location.href = "/";
        }
      });
  }, []);

  const handleClose = () => {
    props.handleCallback(false);
  };

  const renderMessage = (msgProp: {
    _id: string;
    createdAt: string;
    text: string;
    user: {
      _id: number;
    };
  }) => {
    return msgProp.user._id === props.teacherId.toString() ? (
      <div className="yours messages">
        <div id="writer">{props.teacherName}</div>
        <div className="yours message last">
          {msgProp.text}
          <p>{moment(msgProp.createdAt).format("LT")}</p>
        </div>
      </div>
    ) : (
      <div className="mine messages">
        <div id="writer">{props.userName}</div>
        <div className="message last">
          {msgProp.text}
          <p>{moment(msgProp.createdAt).format("LT")}</p>
        </div>
      </div>
    );
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header
        className="text-center"
        closeButton
        style={{
          backgroundColor: "#DDE9FF",
          color: "#5D59B4",
          paddingRight: "30px",
        }}
      >
        <Modal.Title
          style={{
            marginLeft: "40%",
            fontWeight: 600,
            fontSize: "28px",
            lineHeight: "160%",
            border: "none",
            textTransform: "capitalize",
          }}
        >
          Chat History
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          background: "#F9FBFF",
          height: "600px",
          padding: "20px 30px",
          overflow: "scroll",
        }}
      >
        <div>
          {messages &&
            messages?.map((message, key) => {
              if (todayDate === moment(message.createdAt).format("LL")) {
                todayDate = "";
                dateStatus = moment(message.createdAt).format("LL");
                return (
                  <div key={key}>
                    <p className="text-center text-primary">Today</p>
                    {renderMessage(message)}
                  </div>
                );
              } else if (
                dateStatus === moment(message.createdAt).format("LL")
              ) {
                return <div key={key}>{renderMessage(message)}</div>;
              } else {
                dateStatus = moment(message.createdAt).format("LL");
                return (
                  <div key={key}>
                    <p className="text-center text-info">
                      {moment(message.createdAt).format("LL")}
                    </p>
                    {renderMessage(message)}
                  </div>
                );
              }
            })}
        </div>
      </Modal.Body>
    </Modal>
  );
}
