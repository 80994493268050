import moment from "moment";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import BaseUrl from "../../BaseUrl/BaseUrl";
import { IssuesType } from "../../types";
import { convertDate } from "../../utils/dateFunction";
import Chat from "../Chat";
import { getConversationIssue } from "../../services/firestore/getConversationIssue";
import { convertTimestamp } from "../../services/utils/convertTimestamp";
import { updateTypingStatus } from "../../services/database/updateTypingStatus";
import { sendMessage } from "../../services/firestore/sendMessage";
import { markMessagesAsRead } from "../../services/firestore/markMessagesAsRead";
import { getMessages } from "../../services/firestore/getMessages";

type InitiatorType = "teacher" | "user";

interface issuesinfo {
  issuesInfo: {
    issueInfo: IssuesType;
    side: InitiatorType;
    issuesStatus: any;
  };
}

export default function IssueSingle(props: issuesinfo) {
  const issueData = props.issuesInfo.issueInfo;
  const side = props.issuesInfo.side; // side = teacher: Instructor, side = user: User
  const [issueStatus, setIssueStatus] = useState(props.issuesInfo.issuesStatus);
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState(0);
  const [teacherId, setTeacherId] = useState(0);
  const [classId, setClassId] = useState(0);
  const [userName, setUserName] = useState("");
  const [teacherName, setTeacherName] = useState("");
  const [txtValue, setTxtValue] = useState("");
  const conversationId = issueData.firestore_conversation_id;
  const senderId = "0";
  const receiverId = issueData.user_id.toString();

  useEffect(() => {
    if (conversationId && senderId && receiverId) {
      const unsubscribe = getMessages(
        conversationId,
        (messagesCallback: any[]) => {
          setMessages(
            messagesCallback.map((message) => ({
              _id: message.id,
              text: message.text,
              createdAt: convertTimestamp(message.timestamp),
              user: { _id: message.senderId },
            }))
          );
        }
      );

      markMessagesAsRead(conversationId, senderId);

      return () => {
        unsubscribe();
        markMessagesAsRead(conversationId, senderId);
        updateTypingStatus(conversationId, senderId, false);
      };
    }
  }, [conversationId, senderId, receiverId]);

  let dateStatus = "";
  const date = { today: new Date() };
  let todayDate = moment(date.today).format("LL");

  const [messages, _setMessages] = useState<any[]>([]);
  const messagesRef = React.useRef(messages);
  const setMessages = (data) => {
    messagesRef.current = data;
    _setMessages(data);
  };

  const handleParentCallback = (childData: any) => {
    setShowModal(childData);
  };

  const handleTypingStatus = (isTyping: boolean) => {
    updateTypingStatus(conversationId, senderId, isTyping);
  };

  const handleChange = (e) => {
    setTxtValue(e.target.value);
    handleTypingStatus(!!e.target.value.trim().length);
  };

  const handleSend = () => {
    if (txtValue.trim().length < 1) return;

    sendMessage(txtValue, conversationId, senderId, receiverId, "Teache Team");

    setTxtValue("");
    updateTypingStatus(conversationId, senderId, false);
  };

  const handleShowModal = (
    id: any,
    user_id: number,
    teacher_id: number,
    user_name: string,
    teacher_name: string
  ) => {
    setShowModal(true);
    setUserId(user_id);
    setTeacherId(teacher_id);
    setClassId(id);
    setUserName(user_name);
    setTeacherName(teacher_name);
  };

  const handleSetIssus = (status: any) => {
    if (status === "opened") {
      setIssueStatus("solved");
      const data = {
        issue_id: issueData.id,
        solved: "solved",
      };
      const axiosConfig: any = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("teache_token")}`,
        },
      };
      BaseUrl.post(`/update-issue-status`, data, axiosConfig)
        .then((res) => {
          toast.success(res.data.message);
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message);
          }
        });
    } else {
      setIssueStatus("opened");
      const data = {
        issue_id: issueData.id,
        solved: "opened",
      };
      const axiosConfig: any = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("teache_token")}`,
        },
      };

      BaseUrl.post(`/update-issue-status`, data, axiosConfig)
        .then((res) => {
          toast.success(res.data.message);
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message);
          }
        });
    }
  };

  var options: any = { year: "numeric", month: "numeric", day: "numeric" };

  const DateFunc = (val: any) => {
    const formatedDate = new Date(parseInt(val)).toLocaleString(
      "en-US",
      options
    );
    let dateType = formatedDate.replaceAll("/", "-");
    return dateType;
  };

  const renderMessage = ({
    _id,
    text,
    createdAt,
    user,
  }: {
    _id: string;
    createdAt: string;
    text: string;
    user: {
      _id: number | string;
    };
  }) => {
    return (
      <>
        {user && user._id !== "0" ? (
          <div className="yours messages">
            <div id="writer">{issueData.user_name}</div>
            <div className="yours message last">
              {text}
              <p>{moment(createdAt).format("LT")}</p>
            </div>
          </div>
        ) : (
          <div className="mine messages">
            <div id="writer">{"Admin"}</div>
            <div className="message last">
              {text}
              <p>{moment(createdAt).format("LT")}</p>
            </div>
          </div>
        )}
      </>
    );
  };

  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      const observer = new MutationObserver(() => {
        container.scroll({
          top: container.scrollHeight,
          behavior: "smooth",
        });
      });
      observer.observe(container, { childList: true });
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <Fragment>
      <ToastContainer />
      <div className="row">
        <div className="col-xl-12 col-lg-12" style={{ padding: "0px" }}>
          <div
            className="card mb-4"
            style={{
              border: "none",
              borderRadius: "0px",
              background: "#F3F7FF",
            }}
          >
            <div className="card-body" style={{ padding: "0px" }}>
              <div className="d-flex mb-2" style={{ marginTop: "30px" }}></div>

              <div
                className="table-responsive portlet issueTr"
                style={{
                  borderRadius: "10px",
                  boxShadow: "-10px 1px 53px 7px rgba(27, 30, 123, 0.1)",
                  margin: "auto",
                  width: "98%",
                  marginBottom: "20px",
                }}
              >
                <table className="table">
                  <thead>
                    <tr
                      style={{
                        backgroundColor: "#6460F2",
                        color: "white",
                        height: "62px",
                      }}
                      className="rounded-top"
                    >
                      <th
                        className="text-center"
                        scope="col"
                        style={{
                          paddingLeft: 15 + "px",
                          verticalAlign: "middle",
                        }}
                      >
                        ClassID
                      </th>
                      {side === "teacher" ? (
                        <th
                          scope="col"
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          Instructor
                        </th>
                      ) : (
                        <th
                          scope="col"
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          User
                        </th>
                      )}
                      {side === "teacher" ? (
                        <th
                          scope="col"
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          User
                        </th>
                      ) : (
                        <th
                          scope="col"
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          Instructor
                        </th>
                      )}
                      <th
                        scope="col"
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}
                      >
                        Class Date
                      </th>
                      <th
                        scope="col"
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}
                      >
                        Payment
                      </th>
                      <th
                        scope="col"
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}
                      >
                        Cost
                      </th>
                      <th
                        scope="col"
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}
                      >
                        Reported
                      </th>
                      <th
                        scope="col"
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}
                      >
                        Chat
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ color: "#6460F2" }}>
                    <tr className="issueTr text-center">
                      <td style={{ paddingLeft: 15 + "px", color: "#817EB7" }}>
                        <Link
                          to={`/cancelledclasses/${issueData.class_id}`}
                          className="text-teache"
                        >
                          {issueData.class_id}
                        </Link>
                      </td>
                      <td
                        style={{
                          paddingLeft: 15 + "px",
                          textAlign: "center",
                          textDecoration: "none",
                          lineHeight: "160%",
                          color: "#5D59B4",
                        }}
                      >
                        {side === "teacher" ? (
                          <Link
                            style={{ all: "unset", cursor: "pointer" }}
                            to={`/instructor/${issueData.teacher_id}`}
                          >
                            {issueData.teacher_name}
                          </Link>
                        ) : (
                          <Link
                            style={{ all: "unset", cursor: "pointer" }}
                            to={`/users/${issueData.student_user_id}`}
                          >
                            {issueData.student_name}
                          </Link>
                        )}
                      </td>

                      <td style={{ color: "#817EB7", textAlign: "center" }}>
                        {side === "teacher" ? (
                          <Link
                            style={{ all: "unset", cursor: "pointer" }}
                            to={`/users/${issueData.student_user_id}`}
                          >
                            {issueData.student_name}
                          </Link>
                        ) : (
                          <Link
                            style={{ all: "unset", cursor: "pointer" }}
                            to={`/instructor/${issueData.teacher_id}`}
                          >
                            {issueData.teacher_name}
                          </Link>
                        )}
                      </td>
                      <td style={{ color: "#817EB7", textAlign: "center" }}>
                        {convertDate(issueData.class_date)}
                      </td>
                      <td style={{ color: "#817EB7", textAlign: "center" }}>
                        {issueData.payment}
                      </td>
                      <td style={{ color: "#817EB7", textAlign: "center" }}>
                        ${issueData.cost}
                      </td>
                      <td
                        style={{
                          color: "#817EB7",
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        {DateFunc(issueData.created)}
                      </td>
                      <td
                        style={{
                          color: "#817EB7",
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        <Button
                          onClick={() =>
                            handleShowModal(
                              issueData.id,
                              issueData.student_user_id,
                              issueData.teacher_user_id,
                              issueData.student_name,
                              issueData.teacher_name
                            )
                          }
                          style={{
                            background: "none",
                            border: "none",
                            color: "#817EB7",
                          }}
                        >
                          Chat
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                className="table-responsive portlet"
                ref={chatContainerRef}
                style={{
                  borderRadius: "10px",
                  boxShadow: "-10px 1px 53px 7px rgba(27, 30, 123, 0.1)",
                  margin: "auto",
                  width: "98%",
                  padding: "40px 40px 0px",
                  height: "500px",
                  backgroundColor: "white",
                }}
              >
                {messages &&
                  messages?.map((message, key) => {
                    if (!message) return;
                    if (todayDate === moment(message.createdAt).format("LL")) {
                      todayDate = "";
                      dateStatus = moment(message.createdAt).format("LL");
                      return (
                        <div key={key}>
                          <p className="text-center text-primary">Today</p>
                          {renderMessage(message)}
                        </div>
                      );
                    } else if (
                      dateStatus === moment(message.createdAt).format("LL")
                    ) {
                      return <div key={key}>{renderMessage(message)}</div>;
                    } else {
                      dateStatus = moment(message.createdAt).format("LL");
                      return (
                        <div key={key} ref={chatContainerRef}>
                          <p className="text-center text-info">
                            {moment(message.createdAt).format("LL")}
                          </p>
                          {renderMessage(message)}
                        </div>
                      );
                    }
                  })}
              </div>

              <div
                style={{
                  borderRadius: "10px",
                  margin: "auto",
                  width: "98%",
                  marginTop: "30px",
                  background: "#F9FBFF",
                  paddingBottom: "15px",
                  paddingTop: "10px",
                  paddingInline: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <div style={{ flex: 1, height: "100%" }}>
                  <Form.Control
                    as="textarea"
                    onChange={handleChange}
                    value={txtValue}
                    disabled={issueStatus === "solved" ? true : false}
                    style={{ border: "none", padding: "30px" }}
                    placeholder="Type your message..."
                  />
                </div>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="btn btn-block"
                    onClick={handleSend}
                    style={{
                      width: "90%",
                      minWidth: "120px",
                      height: "50px",
                      background: "#6460F2",
                      borderRadius: "8px",
                      border: "none",
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "160%",
                      color: "#FFFFFF",
                    }}
                  >
                    Send
                  </Button>
                  <Button
                    className="btn btn-block"
                    onClick={() => handleSetIssus(issueStatus)}
                    style={{
                      width: "90%",
                      minWidth: "120px",
                      height: "50px",
                      background: "#DDE9FF",
                      borderRadius: "8px",
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "160%",
                      color: "#807CD6",
                      border: "none",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {issueStatus === "solved" ? "Re-Open" : "Close issue"}
                  </Button>
                </div>
              </div>
              {showModal && (
                <Chat
                  classId={classId}
                  userId={userId}
                  teacherId={teacherId}
                  userName={userName}
                  teacherName={teacherName}
                  showModal={showModal}
                  handleCallback={handleParentCallback}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
