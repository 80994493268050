import React from "react"

export function getBadge(status: string) {
  let bg, fontColor, _status
  switch (status) {
    case "active":
      bg = "#B8F4DB"
      fontColor = "#246B4D"
      _status = "Active"
      break
    case "inactive":
      bg = "#FFD6D2"
      fontColor = "#A8433A"
      _status = "Inactive"
      break
    case "deleted":
      bg = "#FFD6D2"
      fontColor = "#A8433A"
      _status = "Deleted"
      break
    case "blocked":
      bg = "#DDE9FF"
      fontColor = "#C4C2E9"
      _status = "Blocked"
      break
    default:
      break
  }
  return (
    <span
      // href="#"
      className='badge'
      style={{
        backgroundColor: bg,
        color: fontColor,
        textDecoration: "none",
        borderRadius: "20px",
        width: "86px",
        height: "36px",
        justifyContent: "center",
        alignItems: "center",
        // padding: "6px",
        // padding: '5px 18px',
        padding: "auto",
        paddingInline: "6px",
        margin: "auto",
        fontSize: "15px",
        fontWeight: 400,
        lineHeight: "160%",
        verticalAlign: "middle",
      }}>
      {_status}
    </span>
  )
}

export function getTeacherBadge(status: string) {
  let bg, fontColor, _status
  switch (status) {
    case "accepted":
      bg = "#B8F4DB"
      fontColor = "#246B4D"
      _status = "Accepted"
      break
    case "top":
      bg = "#554DF1"
      fontColor = "#fff"
      _status = "Top"
      break
    case "elite":
      bg = "#FF827A"
      fontColor = "#fff"
      _status = "Elite"
      break
    case "pro":
      bg = "#275317"
      fontColor = "#fff"
      _status = "Pro"
      break
    case "awaiting":
      bg = "#616161"
      fontColor = "#F6F2EE"
      _status = "Awaiting"
      break
    case "rejected":
      bg = "#DDE9FF"
      fontColor = "#C4C2E9"
      _status = "Blocked"
      break
    case "inactive":
      bg = "#FFD6D2"
      fontColor = "#A8433A"
      _status = "Inactive"
      break
    case "blocked":
      bg = "#DDE9FF"
      fontColor = "#C4C2E9"
      _status = "Blocked"
      break
    default:
      break
  }
  return (
    <span
      // href="#"
      className='badge'
      style={{
        backgroundColor: bg,
        color: fontColor,
        textDecoration: "none",
        borderRadius: "20px",
        width: "86px",
        height: "36px",
        justifyContent: "center",
        alignItems: "center",
        // padding: "6px",
        // padding: '5px 18px',
        padding: "auto",
        paddingInline: "6px",
        margin: "auto",
        fontSize: "15px",
        fontWeight: 400,
        lineHeight: "160%",
        verticalAlign: "middle",
      }}>
      {_status}
    </span>
  )
}

export function getStatus(status: string) {
  let bg, fontColor, _status
  switch (status) {
    case "Upcoming":
      bg = "#DDE9FF"
      fontColor = "#807CD6"
      _status = "Upcomming"
      break
    case "Completed":
      bg = "#B8F4DB"
      fontColor = "#246B4D"
      _status = "Completed"
      break
    case "Cancelled":
      bg = "#FFD6D2"
      fontColor = "#A8433A"
      _status = "Cancelled"
      break
    default:
      break
  }
  return (
    <span
      // href="#"
      className='badge'
      style={{
        backgroundColor: bg,
        color: fontColor,
        textDecoration: "none",
        borderRadius: "20px",
        width: "117px",
        height: "36px",
        justifyContent: "center",
        alignItems: "center",
        // padding: "6px",
        // padding: '5px 18px',
        padding: "auto",
        paddingInline: "6px",
        margin: "auto",
        // fontSize: "16px",
        fontSize: "15px",
        fontWeight: 400,
        lineHeight: "160%",
        verticalAlign: "middle",
      }}>
      {_status}
    </span>
  )
}

export function getAbbr(country: string) {
  let abbr
  switch (country) {
    case "New York":
      abbr = "NY"
      break
    case "Virginia":
      abbr = "VA"

      break
    case "Mc Lean":
      abbr = "ML"

      break
    case "Brooklyn":
      abbr = "Bk"

      break
    case "Texas":
      abbr = "TX"

      break
    case "New Jersey":
      abbr = "NJ"

      break

    default:
      break
  }
  return abbr
}

export function abbrState(input, to) {
  var states = [
    ["Alabama", "AL"],
    ["Alaska", "AK"],
    ["Arizona", "AZ"],
    ["Arkansas", "AR"],
    ["California", "CA"],
    ["Colorado", "CO"],
    ["Connecticut", "CT"],
    ["Delaware", "DE"],
    ["District of Columbia", "DC"],
    ["Florida", "FL"],
    ["Georgia", "GA"],
    ["Hawaii", "HI"],
    ["Idaho", "ID"],
    ["Illinois", "IL"],
    ["Indiana", "IN"],
    ["Iowa", "IA"],
    ["Kansas", "KS"],
    ["Kentucky", "KY"],
    ["Louisiana", "LA"],
    ["Maine", "ME"],
    ["Maryland", "MD"],
    ["Massachusetts", "MA"],
    ["Michigan", "MI"],
    ["Minnesota", "MN"],
    ["Mississippi", "MS"],
    ["Missouri", "MO"],
    ["Montana", "MT"],
    ["Nebraska", "NE"],
    ["Nevada", "NV"],
    ["New Hampshire", "NH"],
    ["New Jersey", "NJ"],
    ["New Mexico", "NM"],
    ["New York", "NY"],
    ["North Carolina", "NC"],
    ["North Dakota", "ND"],
    ["Ohio", "OH"],
    ["Oklahoma", "OK"],
    ["Oregon", "OR"],
    ["Pennsylvania", "PA"],
    ["Puerto Rico", "PR"],
    ["Rhode Island", "RI"],
    ["South Carolina", "SC"],
    ["South Dakota", "SD"],
    ["Tennessee", "TN"],
    ["Texas", "TX"],
    ["Utah", "UT"],
    ["Vermont", "VT"],
    ["Virginia", "VA"],
    ["Washington", "WA"],
    ["West Virginia", "WV"],
    ["Wisconsin", "WI"],
    ["Wyoming", "WY"],
  ]

  let i = 0
  if (to === "abbr") {
    input = input.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
    for (i = 0; i < states.length; i++) {
      if (states[i][0] === input) {
        return states[i][1]
      }
    }
  } else if (to === "name") {
    input = input.toUpperCase()
    for (i = 0; i < states.length; i++) {
      if (states[i][1] === input) {
        return states[i][0]
      }
    }
  }
}
